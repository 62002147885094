import axios from 'axios';
import Identity from '../../config/mocks/identity';

// axios.create({ withCredentials: true });

const documentsService = {};

documentsService.downloadDocumento = path =>
	axios.get(`/Documento/Download`, {
		params: { path, ID: Identity.ID() },
		responseType: 'blob',
	},
	);

documentsService.downloadRentabilidade = path =>
	axios.get(`/Rentabilidade/${path}`, {
		params: {ID: Identity.ID()},
		responseType: 'arraybuffer',
		headers: {
			Accept: 'application/vnd.ms-excel',
		},
	});

documentsService.downloadComposicao = path =>
	axios.get(`/Composicao/${path}`, {
		params: {ID: Identity.ID()},
		responseType: 'arraybuffer',
		headers: {
			Accept: 'application/vnd.ms-excel',
		},
	});


export { documentsService };
