import React from 'react';

import { Container } from './styles';

function SubmenuItem({ label, onClick, href }) {
	return (
		<Container>
			<div />
			{/* <a href={href}> */}
			<span onClick={onClick} style={{ fontWeight: 'bold' }}>
				{label}
			</span>
			{/* </a> */}
		</Container>
	);
}

export default SubmenuItem;
