import styled from 'styled-components';
import { ReactComponent as DownloadSvg } from '../../assets/icons/download-cesta.svg';

export const Container = styled.div`
	display: flex;
	justify-content: space-between;

	color: var(--white);

	height: 100%;

	--theme-color: var(--dark-blue);
	--scrollbar-color: var(--light-blue);

	--button-background: var(--white);
	--button-background-hover: var(--white);
	--button-text: var(--dark-blue);
	--button-text-hover: var(--light-blue);

	--head-background: var(--light-blue);
	--head-text: var(--white);

	--disabled-options-text: var(--light-blue);
	--active-options-text: var(--white);
	--options-text-hover: var(--gray);

	--body-text: var(--white);

	&.dark-blue {
		--theme-color: var(--dark-blue);
		--scrollbar-color: var(--white);

		--button-background: var(--white);
		--button-background-hover: var(--white);
		--button-text: var(--dark-blue);
		--button-text-hover: var(--light-blue);

		--head-background: var(--light-blue);
		--head-text: var(--white);
		--head-border: var(--dark-blue);

		--disabled-options-text: var(--light-blue);
		--active-options-text: var(--white);
		--options-text-hover: var(--gray);

		--body-text: var(--white);
	}

	&.light-blue {
		--theme-color: var(--light-blue);
		--scrollbar-color: var(--dark-blue);

		--button-background: var(--white);
		--button-background-hover: var(--white);
		--button-text: var(--dark-blue);
		--button-text-hover: var(--light-blue);

		--head-background: var(--dark-blue);
		--head-text: var(--white);
		--head-border: var(--light-blue);

		--disabled-options-text: var(--dark-blue);
		--active-options-text: var(--white);
		--options-text-hover: var(--gray);

		--body-text: var(--white);
	}

	&.white {
		--theme-color: var(--white);
		--scrollbar-color: var(--light-blue);

		--button-background: var(--dark-blue);
		--button-background-hover: var(--light-blue);
		--button-text: var(--white);
		--button-text-hover: var(--white);

		--head-background: var(--dark-blue);
		--head-text: var(--white);
		--head-border: var(--light-blue);

		--disabled-options-text: var(--light-blue);
		--active-options-text: var(--dark-blue);
		--options-text-hover: var(--dark-blue);

		--body-text: var(--black);
	}

	@media (max-width: 780px) {
		height: 95%;
	}
`;
export const TableContainer = styled.div`
	flex: 1;
	height: 100%;
	max-height: 520px;

	overflow: overlay;

	background-color: var(--theme-color);

	::-webkit-scrollbar {
		background-color: var(--theme-color);
		width: 5px;
		height: 5px;
	}

	::-webkit-scrollbar-thumb {
		background-color: var(--scrollbar-color);
		border-radius: 2px;
	}

	::-webkit-scrollbar-button:vertical:decrement,
	::-webkit-scrollbar-button:vertical:increment {
		height: 0px;
	}

	::-webkit-scrollbar-track {
		background-color: var(--theme-color);
		/* margin-top: 62px; */
	}

	@media (max-width: 780px) {
		/* height: 70%; */
	}
`;
export const Table = styled.table`
	width: 100%;
	text-align: left;
	font-size: 13px;
`;
export const Head = styled.thead`
	background-color: var(--head-background);
	color: var(--head-text);

	&.customHeader {
		> tr > th {
			padding: 10px 0px 10px 10px;
			vertical-align: middle;
			min-width: 65px;
		}
	}

	> tr {
		> th {
			padding: 25px 0px 25px 10px;
			border-right: 1.5px solid var(--head-border);
			text-transform: uppercase;
			font-weight: normal;
			min-width: 90px;
		}
		> th:last-child {
			border: 0;
			padding-right: 10px;
		}
	}
`;
export const Body = styled.tbody`
	> tr {
		border-bottom: 1px solid var(--gray);

		> td {
			padding: 15px 0px 15px 10px;
			color: var(--body-text);
		}
	}

	.loading {
		border: none;
		padding: 0;
	}
`;
export const Options = styled.div`
	width: 195px;
	padding: 0px 0px 0px 30px;

	display: flex;
	flex-direction: column;
	justify-content: space-around;

	@media (max-width: 780px) {
		/* height: 75%; */
	}
`;
export const Filtro = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: space-around;
	margin: 0px;
`;
export const Tabs = styled.ul`
	display: flex;
	justify-content: flex-start;
`;
export const Tab = styled.li`
	color: var(--disabled-options-text);
	text-align: left;
	display: flex;
	justify-content: center;

	cursor: pointer;

	&.active {
		color: var(--active-options-text);
		font-weight: bold;
	}

	:hover,
	:focus {
		color: var(--options-text-hover);
	}
`;
export const Items = styled.ul`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	padding: 20px 0px;
`;
export const Item = styled.li`
	color: var(--disabled-options-text);
	text-align: left;

	padding-left: 15px;
	padding-bottom: 1px;
	margin-bottom: 10px;

	border-left: 1px solid var(--disabled-options-text);
	border-bottom: 1px solid var(--disabled-options-text);

	cursor: pointer;

	&.active {
		color: var(--active-options-text);
		font-weight: bold;

		border-left: 1px solid var(--active-options-text);
		border-bottom: 1px solid var(--active-options-text);
	}

	:hover,
	:focus {
		color: var(--options-text-hover);

		border-left: 1px solid var(--options-text-hover);
		border-bottom: 1px solid var(--options-text-hover);
	}
`;
export const Download = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin: 0 auto;
	padding: 30px;

	width: 130px;
	height: 130px;

	border-radius: 50%;

	background-color: var(--button-background);

	cursor: pointer;

	color: var(--button-text);
	fill: var(--button-text);

	transition: all ease 0.2s;

	:hover {
		background-color: var(--button-background-hover);
		color: var(--button-text-hover);
		fill: var(--button-text-hover);
	}
`;
export const DownloadIcon = styled(DownloadSvg)`
	fill: inherit;

	width: 40px;
	height: 40px;

	cursor: pointer;
`;
export const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;

	/* border: 1px solid yellow; */

	margin-top: 50px;

	svg {
		stroke: var(--head-background);
	}
`;

export const HoraAtualizacao = styled.div`

width: 100%;
display: flex;
justify-content: flex-end;
padding-top: 10px;
padding: 5px;
height:auto;
margin-top: 20px;
margin-bottom: 20px;
margin: 0;

h2 {
	min-width: 100px;
	max-height: 20px;
	color: darkblue;
	font-size: 14px;
	align: center;
	z-index: 0;
	margin: 0 !important;
}

img{
	width: auto !important;
	height:18px;
	z-index:0;
	margin-left: 5px;
	margin-top : 0 !important;
	
}

`;

export const HoraAtualizacao2 = styled.div`

width: 80%;
display: flex;
justify-content: flex-end;
height:auto;
margin-top: -20px;

> h2 {
	min-width: 100px;
	color: white;
	font-size: 14px;
	align: center;
}

img{
	width: unset;
	height:18px;
	z-index:0;
	margin-left: 5px;
}

`;