export default {
	Escolha: {
		Politicainvestimento:
			'Para investir no FMP Eletrobras do BTG Pactual, é necessário informar sua escolha à Caixa através do app FGTS. Sem esta autorização, a aplicação não pode ser reservada',
		Acesso3:
			'Nesta tela é onde você irá nos autorizar como Instituição escolhida, e também onde você pode pode consultar os valores que pode aplicar no FMP do BTG Pactual.',
		Simulador1:
			'Simule os valores que você pode aplicar nos Fundos Mútuos de Privatização',
		Simula1:
			'Nesta opção você consulta e simula os valores de sua conta vinculada que podem ser aplicados no FMP FGTS do BTG Pactual.',
		Simulador2: 'Autorize bancos a consultarem suas informações',
		Simula2:
			'Nesta opção você autoriza o BTG Pactual a consultar suas informações do FGTS para reservar o valor da aplicação que você escolher no FMP.',
		Smarts1:
			'Depois de clicar em “Autorização de consulta às informações do FGTS”, vá até a opção “Aplicação nos fundos mútuos de privatização FGTS”, selecione o fundo “FMP ELETROBRAS” e clique em “Continuar”.',
		Smarts2:
			'Para seguir, você deve ler e aceitar o Termo de Autorização para Habilitação FMP.',
		Smarts3:
			'Para finalizar, escolha o BANCO BTG PACTUAL na lista de Administradoras FMP e confirme sua seleção!',
		Finalizado:
			'Pronto! Você acaba de avisar à Caixa que escolheu o BTG Pactual para realizar seu investimento no FMP FGTS Eletrobras.',
	},
	SoliciteReserva: {
		Subtitulo:
			'Depois de informar à Caixa a sua escolha pelo FMP Eletrobras do BTG Pactual, basta entrar na sua conta do BTG Investimentos e solicitar a reserva para o seu assessor.',
		Passo1:
			'Ainda não tem conta? Baixe o aplicativo agora e abra sua conta 100% online. Você não paga taxa de abertura ou de manutenção de conta.',
		Passo2:
			'Não lembra até quanto pode aplicar? Vá até o app FGTS, entre no card “Autorizar bancos a consultarem seu FGTS” e verifique seu saldo em “Simulador de Aplicação no FMP - FGTS”. O valor mínimo é de R$ 200 (duzentos reais).',
		Passo3:
			'Você receberá do seu assessor uma notificação/push no celular, contendo o Termo de Adesão do Fundo e o valor solicitado para que você realize a confirmação de seu pedido.',
		Concluido:
			'Agora sim! Sua reserva para investir seu FGTS no FMP Eletrobras do BTG Pactual foi realizada com sucesso!',
	},
	Atencao: {
		Aviso:
			'Fique atento! As solicitações de reserva poderão ser feitas apenas durante o período de ',
		Data: '03/06/2022 até 08/06/2022 às 12h',
	},
};
