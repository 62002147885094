import styled from 'styled-components';

export const Container = styled.li`
	display: flex;
	align-items: center;
	margin: 0 17px;

	> div {
		width: 5px;
		height: 15px;

		background-color: var(--light-blue);
	}

	> span {
		color: var(--dark-blue);
		font-weight: bold;

		margin: 0px 7px;

		cursor: pointer;

		transition: all ease 0.2s;

		:hover {
			color: var(--light-blue);
		}
	}
`;
