import styled from 'styled-components';
import Capa from "./../../assets/imgs/_capa.jpg";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-end;

	width: 100%;
	height: 425px;

	background-image: url(${Capa});
	background-size: cover;
	background-position: bottom right;
`;

export const Menu = styled.div`
	display: flex;
	justify-content: center;

	width: 90%;
	max-width: 1141px;

	z-index: 1;

	&.fixed {
		position: fixed;
		top: 0;
		height: 40px;
		width: 100%;
		max-width: unset;
		background: #001e62;
	}

	@media (min-width: 768px) {
		width: 100%;
	}
`;
