import styled from 'styled-components';

export const Container = styled.nav`
	display: flex;
	justify-content: center;

	background-color: var(--white);

	width: 100%;
	z-index: 1;

	&.fixed {
		position: fixed;
		top: 40px;
	}
`;

export const Items = styled.ul`
	display: flex;
	justify-content: center;

	margin: auto;
	padding: 20px;

	width: 100%;
	max-width: 1141px;
`;
