import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Sobrefgtseletrobras from './pages/sobre-fgts-eletrobras/sobre-fgts-eletrobras';
import Header from './components/header/header';
import { Main, Footer, BackToTop } from './components';
import { ComoInvestir, VisaoGeral, Caracteristicas, Documentos } from './pages';

import GlobalStyles from './config/styles/GlobalStyles';
import { MainProvider } from './store/context/main';

export default () => (
	<MainProvider>
		<BrowserRouter>
			<Header />
			<Main />
			<Switch>
				<Route
					path="/asset-management/fgts-eletrobras/sobre-fgts-eletrobras-e-fmp"
					component={Sobrefgtseletrobras}
				/>
				<Route
					path="/asset-management/fgts-eletrobras/visao-geral"
					component={VisaoGeral}
				/>
				<Route
					path="/asset-management/fgts-eletrobras/caracteristicas"
					component={Caracteristicas}
				/>
				<Route
					path="/asset-management/fgts-eletrobras/documentos"
					component={Documentos}
				/>
				<Route
					path="/asset-management/fgts-eletrobras/como-investir"
					component={ComoInvestir}
				/>
				<Redirect
					from="*"
					to="/asset-management/fgts-eletrobras/como-investir"
				/>
				
			</Switch>
			<BackToTop />
			<Footer />

			<GlobalStyles />
		</BrowserRouter>
	</MainProvider>
);
