import React, { useContext, useEffect, useState } from 'react';

import { MainContext } from '../../store/context/main';
import {
	ArrowUp,
	OffContainer,
	OffContainerModal,
	Container,
	Money,
	Calendar,
	BoxUp,
	ButtonModal,
} from './styles';
import { smoothScroll } from '../../utils/functions';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Link, useLocation } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import CalendarModal from '../../assets/imgs/calendar-days-solid.svg';
import { Button, Dialog } from '@material-ui/core';
import { Tune } from '@material-ui/icons';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
	overrides: {
		MuiTooltip: {
			tooltip: {
				fontSize: '16px',
				width: '150px',
				height: '40px',
				top: '-1px',
				textAlign: 'left',
				padding: '10px',
				color: '#FFF',
				fontWeight: 'bold',
				backgroundColor: '#001E62',
				position: 'relative',
				left: '30px',
				borderRadius: '10px',
			},
			popper: {
				position: 'fixed',
				zIndex: '1',
			},
		},
	},
});

function ScrollToTop() {
	const pathname = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return null;
}
export default () => {
	const { dispatch, state } = useContext(MainContext);

	const handleClick = () => {
		dispatch({
			type: 'SET_SELECTED_MENU',
			selectedMenu: 'menu4',
		});
	};
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<MuiThemeProvider theme={defaultTheme}>
				<div className="App">
					<MuiThemeProvider theme={theme}>
						<Link to="">
							<ScrollToTop />
							<Tooltip placement="left" title="Como investir">
								<OffContainer
									onClick={handleClick}
									className={
										state.currentRoute === 'Como Investir' ? '' : 'show'
									}
								>
									<Money />
								</OffContainer>
							</Tooltip>
						</Link>
					</MuiThemeProvider>
				</div>
			</MuiThemeProvider>
			<MuiThemeProvider theme={defaultTheme}>
				<div className="App">
					<MuiThemeProvider theme={theme}>
						<Tooltip placement="left" title="Cronograma">
							<ButtonModal
								onClick={() => setShowModal(true)}
								className={
									state.currentRoute === 'Como Investir' ? 'show' : 'hidden'
								}
							>
								<Calendar />
							</ButtonModal>
						</Tooltip>
						<Modal
							open={showModal}
							onClose={() => setShowModal(false)}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<BoxUp>
								<hr />
								<ul>
									<li>
										<span style={{ backgroundColor: '#FFBB38' }}>
											<img src={CalendarModal}></img>
										</span>
										<p>
											<strong>
												Cronograma <br /> do FMP
											</strong>
										</p>
									</li>
									<li>
										<span>
											<img src={CalendarModal}></img>
										</span>
										<p>
											<strong>27/05 a 08/06</strong>
										</p>
										<p style={{ fontSize: '12px' }}>
											Autorize o BTG no app do FGTS
										</p>
									</li>
									<li>
										<span>
											<img src={CalendarModal}></img>
										</span>
										<p>
											<strong>03/06 a 08/06</strong>
										</p>
										<p style={{ fontSize: '12px' }}>Solicitação da reserva</p>
									</li>
									<li>
										<span>
											<img src={CalendarModal}></img>
										</span>
										<p>
											<strong>08/06</strong>
										</p>
										<p style={{ fontSize: '12px' }}>
											Encerramento das reservas às 12h
										</p>
									</li>
								</ul>
							</BoxUp>
						</Modal>
					</MuiThemeProvider>
				</div>
			</MuiThemeProvider>
			<Container
				className={state.fixedMenu ? 'show' : ''}
				onClick={() => smoothScroll('#header')}
			>
				<ArrowUp />
			</Container>
		</>
	);
};
