import styled from 'styled-components';
import LogoImg from '../../assets/imgs/logo-fgts.png';
import ComoInvestirImg from '../../assets/imgs/comoinvestir.jpg';

export const Title = styled.h1`
	background-color: white;
	color: #4d4d4d;
	font-size: 1em;
	font-weight: 100;
	text-transform: uppercase;
	padding: 2px 0 11px 0;
	-webkit-box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	-moz-box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	display: inline-block;
	width: 100%;
`;

export const Container = styled.div`
	h2,
	h3 {
		margin-bottom: 10px;
	}

	h2,
	h3,
	p {
		text-align: left;
		color: #707070;
	}
`;

export const ComoInvestir = styled.div`
	display: flex;
	width: 100%;
	height: auto;
	min-height: 300px;
	font-family: 'Moderat', sans-serif;
	font-weight: 100;
	background-image: url(${ComoInvestirImg});
	background-size: cover;
	background-position: center;
	padding: 0 2rem;

	@media (max-width: 959px) {
		min-height: unset;
		padding: 0 0.5rem;
	}
`;

export const ComoInvestirBanner = styled.div`
	width: 100%;
	background-color: #1854c4;
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: start;
	position: relative;

	> hr {
		position: absolute;
		width: 60%;
		overflow: hidden;
		border: 3px dashed #fff;
	}

	> ul {
		display: inline-flex;
		justify-content: space-around;
		align-items: center;
		width: 79%;
		margin-left: 1rem;
		position: relative;
		bottom: 20%;
	}

	> ul li {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	> ul li p {
		color: #fff;
		font-size: 14px;
		text-align: center;
		font-weight: 100;
		line-height: 1.2;
	}

	> ul li span {
		width: 40px;
		height: 40px;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		margin-bottom: 5px;
		border: none;
	}

	> ul li span img {
		width: 50%;
		color: #1854c4;
	}
	@media (max-width: 768px) {
		display: none;
	}
`;

export const ComoInvestirConteudo = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	max-width: 1141px;

	> p {
		color: var(--white);
		font-size: 2rem;
		font-weight: lighter;
		font-family: 'Moderat', sans-serif;
		text-align: left;
	}

	@media (max-width: 959px) {
		height: 7rem;
		width: 60%;

		> p {
			font-size: 1.2rem;
		}
	}
`;
export const LogoFGTS = styled.div`
	display: flex;
	justify-content: center;
	width: 20%;
	background-image: url(${LogoImg});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

	@media (max-width: 959px) {
		width: 40%;
	}
`;

export const NumberCircle = styled.div`
	background-color: var(--dark-blue);
	border-radius: 100%;
	color: var(--white);
	text-align: center;
	vertical-align: middle;
	padding-top: 3.8px;
	padding-right: 1.25px;
	width: 40px;
	height: 38px;
	margin-right: 10px;
	font-size: 23px;
	font-weight: bold;
`;

export const AcessItem = styled.div`
	display: flex;
	align-items: center;
	justify-items: center;
	width: 70%;
	padding: 1rem 1rem 1rem 0.5rem;
	> p {
		text-align: left;
		align-items: center;
		justify-content: center;
		width: 100%;
		line-height: 1.5;
		> p {
			justify-content: center;
			text-align: left;
			align-items: center;
			font-size: smaller;

			> a {
				color: blue;
				font-weight: bold;
				text-decoration-line: underline;
			}
		}
	}
	@media (max-width: 1024px) {
		width: 100%;
		> p {
			text-align: justify;
		}
	}
`;

export const EscolhaBTG = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 500px;
	width: 100%;
	padding-top: 3rem;
	> img {
		position: relative;
		right: 90px;
		height: 639px;
	}
	@media (max-width: 959px) {
		width: 100%;
		padding-top: 0;
		> img {
			display: none;
		}
	}
	@media (max-width: 1024px) {
		> img {
			position: relative;
			right: 30px;
			height: 529px;
		}
	}
`;

export const Line = styled.hr`
	color: #000;
	background-color: #000;
	height: 1px;
`;

export const EscolhaBTGConteudo = styled.div`
	min-height: 500px;
	padding: 40px 0 10px 0;
	width: 50%;
	max-width: 1141px;
	display: flex;
	justify-content: center;
	align-items: left;
	flex-direction: column;
	margin-top: 1rem;

	> p {
		text-align: justify;
		line-height: 1.2rem;
	}

	> img {
		max-width: 350px;
		margin-top: 20px;
	}

	@media (max-width: 959px) {
		width: 100%;
		padding: 0.5rem;
		> h2 {
			text-align: center;
		}
	}
	@media (max-width: 1024px) {
		> h2 {
			text-align: center;
		}
	}
`;
export const Simulador = styled.div`
	display: flex;
	justify-content: center;
	min-height: 500px;
	width: 100%;
	> img {
		position: absolute;
		right: 20rem;
		top: 32%;
		height: 639px;
	}
	@media (max-width: 959px) {
		> img {
			display: none;
		}
	}
	@media (max-width: 1024px) {
		> img {
			position: absolute;
			right: 1rem !important;
			top: 35% !important;
			height: 529px;
		}
	}
	@media (max-width: 1440px) {
		> img {
			position: absolute;
			right: 7rem;
			bottom: 55%;
			height: 529px;
		}
	}
`;
export const SimuladorConteudo = styled.div`
	min-height: 500px;
	width: 80%;
	margin-left: 2rem;
	display: flex;
	justify-content: center;
	align-items: left;
	flex-direction: column;

	> p {
		line-height: 1.5em;

		> strong {
			color: var(--dark-blue);
		}
	}
	> img {
		max-width: 350px;
		margin-top: 20px;
	}

	@media (max-width: 959px) {
		width: 100% !important;
		margin-left: 0;
		justify-content: center !important;
		padding: 0.5rem !important;
	}
	@media (max-width: 1024px) {
		width: 70%;
		margin-left: 0;
		justify-content: flex-start;
	}
`;
export const InformationBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: left;
	margin-top: 3%;
	width: 60%;
	@media (max-width: 1024px) {
		width: 100%;
	}
`;

export const InformationItem = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	height: 60%;

	@media (max-width: 959px) {
		width: 100% !important;
		height: 80% !important;
	}
	@media (max-width: 1024px) {
		width: 80%;
		padding: 1rem 0;
	}
`;

export const InformationItemContent = styled.div`
	width: 45%;
	box-shadow: 3px 3px #e7e9e8;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
		rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
	padding: 20px 10px;
	height: 100%;

	> h3 {
		font-weight: 900;
		text-align: left;
		color: #000;
	}
	> p {
		text-align: left;
	}
	@media (max-width: 959px) {
		width: 45%;
		padding: 0.5rem;
		> h3 {
			font-size: small;
			text-align: center;
		}
		> p {
			font-size: smaller;
			text-align: justify;
		}
	}
`;
export const InformationItemDetail = styled.div`
	width: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80%;
	background-color: #fff9de;

	> p {
		font-size: 14px;
		text-align: left;
		line-height: 1.5;
		padding: 1rem;
	}
	@media (max-width: 959px) {
		width: 55%;

		> p {
			font-size: smaller;
			text-align: justify;
		}
	}
`;

export const Smarts = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 800px;
	width: 100%;
	background-color: #e5ebf1;

	@media (max-width: 959px) {
		justify-content: left;
		min-height: 50%;
	}
`;

export const SmartsImg = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top: 10rem;
	width: 100%;
	> img {
		padding: 0 2rem;
		height: auto;
	}
	@media (max-width: 959px) {
		display: none;
	}
	@media (max-width: 1024px) {
		> img {
			padding: 0 0.5rem;
			height: 439px;
		}
	}
`;

export const SmartsConteudo = styled.div`
	display: flex;
	height: 60%;
	padding: 3rem 0.5rem;
	width: 70%;

	@media (max-width: 959px) {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 0.5rem;
	}
	@media (max-width: 1024px) {
		width: 100%;
		padding: 0 0.5rem;
	}
`;

export const ItemSmarts = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	margin-bottom: 3rem;
`;
export const AcessItemSmarts = styled.div`
	display: flex;
	align-items: center;
	justify-items: center;
	width: 100%;
	height: 100%;

	padding: 1rem 1rem 1rem 0.5rem;
	> p {
		text-align: left;
		align-items: center;
		justify-content: center;
		width: 100%;
		line-height: 1.5;
		> p {
			justify-content: center;
			text-align: left;
			align-items: center;
			font-size: smaller;

			> a {
				color: blue;
				font-weight: bold;
				text-decoration-line: underline;
			}
		}
	}
	@media (max-width: 1024px) {
		width: 100%;
		> p {
			text-align: justify;
		}
	}
`;
export const NumberCircleSmarts = styled.div`
	background-color: var(--dark-blue);
	color: var(--white);
	border-radius: 100%;
	text-align: center;
	vertical-align: middle;
	padding-top: 1.9px;
	width: 40px;
	height: 36px;
	margin-right: 10px;
	font-size: 23px;
	font-weight: bold;
`;

export const Finalizado = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: right;
	min-height: 450px;
	width: 100%;

	@media (max-width: 959px) {
		min-height: 50%;
	}
`;

export const FinalizadoImg = styled.div`
	> img {
		position: absolute;
		top: 185rem;
		left: 20rem;
		height: auto;
	}
	@media (max-width: 959px) {
		display: none;
	}
	@media (max-width: 1024px) {
		> img {
			position: relative;
			top: 180rem !important;
			left: 2rem !important;
			height: 529px;
		}
	}
	@media (max-width: 1440px) {
		> img {
			position: absolute;
			top: 186rem;
			left: 15rem;
			height: 529px;
		}
	}
`;

export const FinalizadoConteudo = styled.div`
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	padding-left: 20rem;
	> div {
		display: flex;
		justify-content: left;
		align-items: center;
		border-top: solid 2px #000;
		padding: 1rem 0;

		> p {
			text-align: left;
			font-size: 1rem;
			margin-left: 1rem;
		}
	}

	> p {
		text-align: left;
		font-size: 1rem;
		font-weight: bold;
		margin-left: 4rem;
	}

	@media (max-width: 959px) {
		width: 100%;
		padding: 1.8rem;
		align-content: center;
		justify-content: center;

		> div {
			> p {
				text-align: center;
			}
		}

		> p {
			text-align: center;
		}
	}
	@media (max-width: 1024px) {
		width: 90%;
	}
`;
export const SoliciteReserva = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 700px;
	width: 100%;
	background-color: #091e42;
`;
export const SoliciteReservaTitulo = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 10rem;
	padding-left: 17.5rem;
	width: 100%;
	color: #ffff;
	> h2 {
		color: #fff;
	}
	> p {
		padding-top: 1.5rem;
		color: #fff;
	}
	@media (max-width: 959px) {
		width: 100%;
		padding: 0.5rem;
		> h2 {
			text-align: center;
		}
		> p {
			text-align: justify;
		}
	}
	@media (max-width: 1024px) {
		width: 100%;
		padding-top: 10rem;
		padding-left: 5rem;
	}
	@media (max-width: 1440px) {
		h2,
		p {
			position: relative;
			right: 2rem;
		}
	}
`;
export const SoliciteReservaMain = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 5rem;
	padding-bottom: 1rem;
	width: 100%;
	@media (max-width: 959px) {
		padding-top: 1rem;
	}
`;

export const SoliciteReservaConteudo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: left;
	padding-left: 5rem;
	width: 70%;
	height: 100%;
	@media (max-width: 959px) {
		width: 100% !important;
		padding: 0.5rem !important;
	}
	@media (max-width: 1024px) {
		width: 70%;
		padding-left: 0;
	}
`;

export const SoliciteReservaImg = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30%;
	padding-right: 10rem;
	> img {
		height: auto;

		align-items: center;
		justify-content: center;
	}
	@media (max-width: 1024px) {
		display: none;
	}
`;

export const ItemReserva = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin-bottom: 3rem;
`;
export const ItemReservaImg = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 70%;
	height: 100%;
	padding-left: 3.5rem;
	margin-bottom: 2rem;
	> a {
		> img {
			margin-right: 0.5rem;
		}
	}
	@media (max-width: 959px) {
		justify-content: center;
		padding-left: 0;
	}
`;
export const ItemReservaTitulo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	text-align: justify;
	width: 100%;
	height: 20%;

	> p {
		text-align: justify;
	}
`;
export const ItemReservaConcluido = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: justify;
	width: 70%;
	height: 5rem;
	padding: 0 0.5rem;
	border-radius: 0.5rem;
	background-color: #f5f8fd;
	color: #707070;
	font-weight: bold;
	> p {
		padding-left: 0.5rem;
	}
	@media (max-width: 1024px) {
		width: 100%;
		> p {
			text-align: center;
		}
	}
`;

export const NumberCircleReserva = styled.div`
	background-color: var(--light-blue);
	color: var(--white);
	border-radius: 100%;
	text-align: center;
	vertical-align: middle;
	padding-top: 5px;
	width: 40px;
	height: 40px;
	margin-right: 10px;
	font-size: 23px;
	font-weight: bold;
`;

export const Atencao = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100px;
	width: 100%;
	background-color: #091e42;
	color: #707070;
	font-size: 1rem;
	background-color: #ffffff;
	> p {
		padding-bottom: 0.5rem;
	}
	@media (max-width: 959px) {
		width: 100%;
		> p {
			text-align: center;
		}
	}
`;
