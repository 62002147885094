import React from 'react';

import { TableContainer, Table, Head, Body } from './styles';

function Tabela({ highlight, headers, data, children }) {
	return (
		<TableContainer>
			<Table>
				<Head>
					<tr>
						{headers?.map(header => (
							<th
								key={header}
								className={header === highlight ? 'highlight' : ''}
							>
								{header}
							</th>
						))}
					</tr>
				</Head>
				<Body>
					{children ||
						data?.map((items, i) => (
							<tr key={items + i}>
								{items.map((item, j) => (
									<td key={item + j}>{item}</td>
								))}
							</tr>
						))}
				</Body>
			</Table>
		</TableContainer>
	);
}

export default Tabela;
