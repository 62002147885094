import React, { useState, useEffect, useRef, useContext } from 'react';
import { Hidden } from '@material-ui/core';
import './sobre-fgts-eletrobras.css';
import { CTAButton, Submenu, SubmenuItem } from '../../components';
import { documentsService } from '../../service';
import CheckIcon from '../../assets/imgs/circle-check-solid.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Grid } from '@material-ui/core';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { MainContext } from '../../store/context/main';

import Mock from './mock';

import {
	Container,
	Title,
	PeriodOptions,
	PeriodOption,
	DateOptions,
	BoxAcumulado,
	ContentAcumulado,
	BoxMensal,
	ContentMensal,
	TabelaMensal,
	BoxVolume,
	ContentVolume,
	TabelaVolume,
	BoxEvolucao,
	ContentEvolucao,
	TabelaEvolucao,
	BoxAcumuladoMensal,
	BoxAcumuladoMensalVolume,
	BottomButton,
	CTAContainer,
} from './styles';

import {
	getDateTimeString,
	smoothScroll,
	formatCurrencyToBRL,
	convertBRLDateToString,
	convertBRLDateToDate,
} from '../../utils/functions';

const initialOptions = {
	chart: {
		type: 'spline',
	},

	credits: { enabled: false },

	title: {
		text: undefined,
	},

	tooltip: {
		shared: true,
		useHTML: true,
		headerFormat:
			'<p class="tooltipHeader">{point.key}</p><table class="tooltipInfo">',
		pointFormat:
			'<tr><td style="color: {series.color}">{series.name}:</td>' +
			'<td><div style="background-color: {series.color}"> {point.y_info:.3f}%</div></td></tr>',
		footerFormat: '</table>',
	},

	plotOptions: {
		series: {
			compare: 'percent',
		},
	},

	yAxis: {
		title: {
			text: 'Rentabilidade (%)',
		},
		labels: {
			formatter: function() {
				return formatCurrencyToBRL(this.value);
			},
		},
	},

	series: [
		{
			name: 'FGTS (TR+3%)',
			color: '#487B00',
			label: {
				formatter: function() {
					return 'R$ ' + formatCurrencyToBRL(this.value);
				},
			},
		},
		{
			name: 'ELET3',
			color: '#00467D',
			label: {
				formatter: function() {
					return 'R$ ' + formatCurrencyToBRL(this.value);
				},
			},
		},
	],
};

export default () => {
	const [chart, setChart] = useState(initialOptions);
	const { state, dispatch } = useContext(MainContext);

	const [result, setResult] = useState('');

	const getResult = async () => {
		try {
			throw new Error(); //force mock
		} catch (err) {
			setResult(Mock);
		}
	};

	const getFile = async path => {
		try {
			const response = await documentsService.downloadDocumento(path);
			const responseData = await response.data;

			const blob = new Blob([responseData], {
				type: 'application/pdf',
			});

			const downloadUrl = window.URL.createObjectURL(blob);

			const link = document.createElement('a');

			link.href = downloadUrl;
			link.download = `${path.replace('/', '')}PDF_${getDateTimeString()}.pdf`;

			document.body.appendChild(link);

			link.click();
			link.remove();
		} catch (err) {
			console.error(
				'Ocorreu um erro ao chamar a API de Download - Erro: ',
				err,
			);
		}
	};

	const handleDownloadClick = path => getFile(path);

	const resetChart = () =>
		setChart(prev => {
			return {
				...prev,
				series: [
					{
						...prev?.series[0],
						data: [],
					},
					{
						...prev?.series[1],
						data: [],
					},
				],
			};
		});

	const getChart = async (
		beginDateParam = null,
		endDateParam = null,
		periodParam = null,
	) => {
		try {
			resetChart();

			const responseData = Mock.Mock.ObjJsonResultado;

			if (responseData?.periodos?.periodo?.length > 0) {
				const categories = responseData.periodos.periodo.map(
					convertBRLDateToString,
				);

				var formato = {
					maximumFractionDigits: 10,
					style: 'currency',
					currency: 'BRL',
				};

				const etf = {
					values: responseData.series[0].etf.map((e, index) => {
						return {
							name: categories[index],

							y: e.PercetualIndiceRef,
							y_info: e.PercetualIndiceRef.toFixed(2).toLocaleString('pt-br'),
						};
					}),
					labels: responseData.series[0].etf.map(e => e.PercetualIndiceRef),
				};
				const ind = {
					values: responseData.series[0].indice.map((e, index) => {
						return {
							name: categories[index],

							y: e.PercetualIndiceRef,
							y_info: e.PercetualIndiceRef.toFixed(2).toLocaleString('pt-br'),
						};
					}),
					labels: responseData.series[0].indice.map(e => e.PercetualIndiceRef),
				};

				setChart(prev => {
					return {
						...prev,
						xAxis: { categories },
						series: [
							{
								...prev?.series[0],
								data: etf.values,
							},
							{
								...prev?.series[1],
								data: ind.values,
							},
						],
					};
				});
			} else {
				setChart(prev => {
					return {
						...prev,
						xAxis: { categories: [] },

						series: [
							{
								...prev?.series[0],
								data: [],
							},
							{
								...prev?.series[1],
								data: [],
							},
						],
					};
				});
			}
		} catch (err) {
			resetChart();

			console.error(
				'Ocorreu um erro ao chamar a API de Rentabilidade Acumalada - Erro: ',
				err,
			);
		}
	};

	useEffect(() => {
		const getData = async () => {
			await getResult();
			await getChart(null, null, 3);
		};

		getData();

		dispatch({
			type: 'SET_CURRENT_ROUTE',
			currentRoute: 'Sobre FGTS E FMP',
		});
	}, []);

	return (
		<Container>
			<div className="sobre-fgts-eletrobras">
				<Hidden mdUp>
					<h1 className="titulohSobre1">Sobre FGTS e FMP</h1>
				</Hidden>
				<Hidden smDown>
					<Submenu>
						<SubmenuItem
							onClick={() => smoothScroll('#oqueefgts')}
							label="O que é FGTS"
						/>
						<SubmenuItem
							onClick={() => smoothScroll('#fmpfgts')}
							label="O que é FMP FGTS"
						/>
						<SubmenuItem
							onClick={() => smoothScroll('#fmpfgtsbtg')}
							label="O FMP FGTS Eletrobras do BTG PACTUAL"
						/>
					</Submenu>
				</Hidden>

				<div className="oqueefgts-eletrobras">
					<div className="conteudooquefgts-eletrobras">
						<div className="conteudoconteudooquefgts-eletrobras">
							<h2 id="oqueefgts" className="corazulclaro titulo">
								O QUE É FGTS
							</h2>
							<p className="corbranca texto" style={{ textAlign: 'justify' }}>
								O FGTS, ou Fundo de Garantia do Tempo de Serviço, é um fundo que
								cada trabalhador possui na Caixa Econômica Federal. Mensalmente,
								a empresa empregadora deposita o valor de 8% do salário bruto do
								funcionário nesta conta.
							</p>
							<br />
							<p className="corbranca texto" style={{ textAlign: 'justify' }}>
								Os valores depositados na conta do FGTS do trabalhador possuem
								uma remuneração mensal de TR mais 3% ao ano.
							</p>
							<br />
							<p className="corbranca texto" style={{ textAlign: 'justify' }}>
								Estes recursos podem ser utilizados/sacados pelo trabalhador,
								resumidamente, nas seguintes situações:
							</p>
							<br />
							<div>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									align="center"
									justify="center"
								>
									<Grid
										item
										xs={6}
										style={{ textAlign: 'left', marginTop: 10 }}
									>
										<CheckCircleIcon
											style={{ color: 'white', float: 'left' }}
										/>
										<h style={{ color: 'white', marginLeft: 10 }}>
											Demissão sem justa causa
										</h>
									</Grid>
									<Grid
										item
										xs={6}
										style={{ textAlign: 'left', marginTop: 10 }}
									>
										<CheckCircleIcon
											style={{ color: 'white', float: 'left' }}
										/>
										<h style={{ color: 'white', marginLeft: 10 }}>
											Estado de calamidade pública
										</h>
									</Grid>
									<br />
									<Grid
										item
										xs={6}
										style={{ textAlign: 'left', marginTop: 10 }}
									>
										<CheckCircleIcon
											style={{ color: 'white', float: 'left' }}
										/>
										<h style={{ color: 'white', marginLeft: 10 }}>
											Aposentadoria
										</h>
									</Grid>
									<Grid
										item
										xs={6}
										style={{ textAlign: 'left', marginTop: 10 }}
									>
										<CheckCircleIcon
											style={{ color: 'white', float: 'left' }}
										/>
										<h style={{ color: 'white', marginLeft: 10 }}>
											Término do contrato de trabalho (prazo determinado)
										</h>
									</Grid>
									<br />
									<Grid
										item
										xs={6}
										style={{ textAlign: 'left', marginTop: 10 }}
									>
										<CheckCircleIcon
											style={{ color: 'white', float: 'left' }}
										/>
										<h style={{ color: 'white', marginLeft: 10 }}>
											Aquisição de casa própria
										</h>
									</Grid>
									<Grid
										item
										xs={6}
										style={{ textAlign: 'left', marginTop: 10 }}
									>
										<CheckCircleIcon
											style={{ color: 'white', float: 'left' }}
										/>
										<h style={{ color: 'white', marginLeft: 10 }}>
											Rescisão do contrato de trabalho por comum acordo
										</h>
									</Grid>

									<Grid
										item
										xs={6}
										style={{ textAlign: 'left', marginTop: 10 }}
									>
										<CheckCircleIcon
											style={{ color: 'white', float: 'left' }}
										/>
										<h style={{ color: 'white', marginLeft: 10 }}>
											Doenças graves do trabalhador
										</h>
									</Grid>
									<Grid
										item
										xs={6}
										style={{ textAlign: 'left', marginTop: 10 }}
									>
										<CheckCircleIcon
											style={{ color: 'white', float: 'left' }}
										/>
										<h style={{ color: 'white', marginLeft: 10 }}>
											Falência da empresa empregadora
										</h>
									</Grid>

									<Grid
										item
										xs={6}
										style={{ textAlign: 'left', marginTop: 10 }}
									>
										<CheckCircleIcon
											style={{ color: 'white', float: 'left' }}
										/>
										<h style={{ color: 'white', marginLeft: 10 }}>
											Idade igual ou superior a 70 anos
										</h>
									</Grid>
									<Grid
										item
										xs={6}
										style={{ textAlign: 'left', marginTop: 10 }}
									>
										<CheckCircleIcon
											style={{ color: 'var(--light-blue)', float: 'left' }}
										/>
										<h style={{ color: 'var(--light-blue)', marginLeft: 10 }}>
											Aplicação em FMP (Fundo Mútuo de Privatização)
										</h>
									</Grid>

									<br />
									<br />
								</Grid>
								<br />
								<div
									className="estudosrecomendados"
									style={{ lineHeight: '1.5em' }}
								>
									<p
										className="corbranca texto"
										style={{ textAlign: 'center' }}
									>
										Para entender mais sobre o FGTS, visite o{' '}
										<a
											style={{
												display: 'inline',
												textDecoration: 'underline',
												color: '#FFF',
											}}
											target="_blank"
											href="https://www.fgts.gov.br/Pages/default.aspx"
										>
											site
										</a>
										.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="sobreetf">
					<div className="conteudosobreetf">
						<div className="oquesaoblocomaster">
							<div className="oquesaobloco">
								<div style={{ width: '80%', float: 'left' }}>
									<h2 id="oqueefgts" className="corbranca titulo">
										O QUE É FMP FGTS
									</h2>
									<p className="corbranca texto" style={{ textAlign: 'left' }}>
										O Fundo Mútuo de Privatização, ou FMP, é uma modalidade de
										fundo de investimento criada no Brasil no ano 2000. Este
										tipo de fundo tem como objetivo investir em ações de
										empresas estatais que passam pelo processo de privatização.
										Em 2000, foi criado o FMP Petrobras e, em 2002, o FMP Vale.
									</p>
									<br />
									<p className="corbranca texto" style={{ textAlign: 'left' }}>
										Além disso, estes fundos são constituídos com recursos das
										contas do FGTS dos trabalhadores que desejam ingressar neste
										tipo de investimento.
									</p>
									<br />
									<p className="corbranca texto" style={{ textAlign: 'left' }}>
										O investimento em FMP FGTS deve obedecer um limite máximo de
										aplicação de 50% do saldo da conta do FGTS do trabalhador.
									</p>
									<br />
									<p className="corbranca texto" style={{ textAlign: 'left' }}>
										Caso o investidor deseje que os recursos sejam devolvidos à
										sua conta do FGTS, é possível realizar esta solicitação no
										mínimo 12 meses após a aplicação. As regras para saque
										destes recursos seguem as mesmas regras estabelecidas para
										saques das contas do FGTS.
									</p>
									<br />
									<p className="corbranca texto" style={{ textAlign: 'left' }}>
										A tributação sobre o FMP se dá no momento do resgate, da
										seguinte forma:
									</p>
									<br />
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										align="center"
										justify="center"
										direction="column"
									>
										<Grid
											item
											xs={12}
											style={{ textAlign: 'left', marginTop: 10 }}
										>
											<CheckCircleIcon
												style={{ color: 'white', float: 'left' }}
											/>
											<h style={{ color: 'white', marginLeft: 10 }}>
												Os ganhos incorridos até o limite do rendimento da conta
												do FGTS no mesmo período são isentos de IR;
											</h>
										</Grid>
										<Grid
											item
											xs={12}
											style={{ textAlign: 'left', marginTop: 10 }}
										>
											<CheckCircleIcon
												style={{ color: 'white', float: 'left' }}
											/>
											<h style={{ color: 'white', marginLeft: 10 }}>
												Somente sobre os ganhos que excederem este limite, é
												recolhido IR à alíquota de 15%.
											</h>
										</Grid>
										<br />
									</Grid>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bakcoetfbtg">
					<div className="oetfbtg">
						<div className="conteudooetfbtg">
							<h2 id="fmpfgtsbtg" className="corazulesc titulo">
								O FMP FGTS Eletrobras do BTG PACTUAL
							</h2>
							<p className="texto" style={{ textAlign: 'justify' }}>
								Com o processo de privatização da Eletrobras, surge a
								oportunidade de investimento utilizando até 50% do saldo do FGTS
								do investidor no{' '}
								<b>BTG Pactual Reference FMP FGTS Eletrobras</b>. O Fundo
								investirá os recursos em ações ordinárias da Eletrobras.
							</p>
							<p className="corazulesc textoetfbtg">
								Em nossa visão, investir no{' '}
								<b>BTG Pactual Reference FMP FGTS Eletrobras</b> possui 2
								grandes vantagens:
							</p>
						</div>
					</div>
					<div className="conteudooetfbtgbloco">
						<div className="conteudooetfbtgblocointerno">
							<div className="conteudooetfbtgbloco1">
								<div className="numerocirculo"></div>
								<p className="texto corbranca" style={{ textAlign: 'justify' }}>
									A Eletrobras é uma companhia de setor-chave para a economia
									brasileira. Além disso, empresas em processo de privatização
									costumam apresentar ótimos retornos após a conclusão;
								</p>
							</div>
							<div className="conteudooetfbtgblocotraco">
								<div className="tracoseparador" />
							</div>
							<div className="conteudooetfbtgbloco2">
								<div className="numerocirculo"></div>
								<p
									className="texto corbranca textomargin"
									style={{ textAlign: 'justify' }}
								>
									A rentabilidade histórica da ação ordinária da Eletrobras é
									superior à rentabilidade do FGTS (TR+ 3% a.a.).
								</p>
							</div>
						</div>
					</div>

					<div className="conteudooetfbtgrafico" style={{display:"flex", flexDirection:"column", justifyContent: "center", alignItems: "center"}}>
						<div style={{ width: '90%', maxWidth: '1141px' }}>
							<HighchartsReact
								highcharts={Highcharts}
								options={chart}
								// highcharts={HighStock}
								// constructorType={'stockChart'}
							/>
						</div>
						<br/>
						<p>
							<b>Obs:</b> Rentabilidade obtida no passado não representa
							garantia de resultados futuros
						</p>
					</div>
					<CTAContainer>
						<CTAButton primary />
					</CTAContainer>
				</div>
			</div>
		</Container>
	);
};
