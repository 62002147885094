import React from 'react';
import './App.css';

import GlobalStyles from './config/styles/GlobalStyles';

import Routes from './routes';

function App() {
	return (
		<div className="App">
			<div className="mainMain size">
				<Routes />
			</div>

			<GlobalStyles />
		</div>
	);
}

export default App;
