import React, { useRef, useEffect, useContext } from 'react';
import { MainContext } from '../../store/context/main';

import { CTAButton, Submenu, SubmenuItem } from '../../components';
import { documentsService } from '../../service/services/DocumentsService';
import { getDateTimeString, smoothScroll } from '../../utils/functions';
import { Hidden } from '@material-ui/core';

import {
	Container,
	Title,
	BoxContainer,
	Box,
	Content,
	Block,
	Icon,
	CTAContainer,
} from './styles';

const Button = ({ label, onClick }) => (
	<button onClick={onClick}>
		<Icon />
		<span>{label}</span>
	</button>
);

export default () => {
	const regulamentacaoRef = useRef(null);
	const contratoRef = useRef(null);
	const caracteristicaRef = useRef(null);
	const divulgacaoRef = useRef(null);
	const outrosRef = useRef(null);
	const { state, dispatch } = useContext(MainContext);

	const getFile = async path => {
		try {
			const response = await documentsService.downloadDocumento(path);
			const responseData = await response.data;

			const blob = new Blob([responseData], {
				type: 'application/pdf',
			});

			const downloadUrl = window.URL.createObjectURL(blob);

			const link = document.createElement('a');

			link.href = downloadUrl;
			link.download = `${path.replace('/', '')}PDF_${getDateTimeString()}.pdf`;

			document.body.appendChild(link);

			link.click();
			link.remove();
		} catch (err) {
			console.error(
				'Ocorreu um erro ao chamar a API de Download - Erro: ',
				err,
			);
		}
	};

	useEffect(() => {
		dispatch({
			type: 'SET_CURRENT_ROUTE',
			currentRoute: 'Documentos',
		});
	}, []);


	const handleDownloadClick = path => getFile(path);

	return (
		<Container>
			<Hidden mdUp>
				<Title>Documentos</Title>
			</Hidden>
			<Hidden smDown>
				<Submenu>
					<SubmenuItem
						onClick={() => smoothScroll('#regulamentacao')}
						label="Regulamentação"
					/>
					<SubmenuItem
						onClick={() => smoothScroll('#divulgacao')}
						label="Divulgação"
					/>
				</Submenu>
			</Hidden>

			<BoxContainer>
				<Box>
					<Content ref={regulamentacaoRef}>
						<Block>
							<h2 id="regulamentacao">Regulamentação</h2>
							<Button
								label="Regulamento"
								onClick={() =>
									handleDownloadClick('/Regulamentacao/Regulamento')
								}
							/>
							<Button
								label="ICVM 279"
								onClick={() => handleDownloadClick('/Regulamentacao/ICVM359')}
							/>
							<Button
								label="IN 1585"
								onClick={() => handleDownloadClick('/Regulamentacao/IN1585')}
							/>
						</Block>
					</Content>
				</Box>
				<Box>
					<Content ref={divulgacaoRef} className="align-right">
						<Block className="gray">
							<h2 id="divulgacao">Divulgação</h2>
							<Button
								label="Material de divulgação"
								onClick={() =>
									handleDownloadClick('/Divulgacao/BTGPactual')
								}
							/>
						</Block>
					</Content>
				</Box>
				<CTAContainer>
					<CTAButton />
				</CTAContainer>
			</BoxContainer>
		</Container>
	);
};
