import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Container, Content } from './styles';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
	},
}));

export default function SimpleAccordion() {
	const classes = useStyles();

	return (
		<Container>
			<Content>
				<Grid container>
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<picture className="logomarcaFooter">
							<img
								src="images/btg-pactual-logomarca.png"
								alt="Logomarca BTG Pactual"
							/>
						</picture>
					</Grid>

					<Grid item lg={6} className="textoRodape enderecoRodape">
						Av. Brigadeiro Faria Lima, 3.477 São Paulo - SP - Brasil - Telefone:
						+55 11 3383 2000
						<br />
						Praia de Botafogo, 501 Rio de Janeiro - RJ - Brasil - Telefone: +55
						21 3262 9600
					</Grid>

					<Grid item lg={12} md={12} sm={12} xs={12} className="avisos">
						<div className={classes.root}>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									Avisos legais
								</AccordionSummary>
								<AccordionDetails>
									<div className="box-avisoslinhas">
										<p className="avisoslinhas">
											A autorização para venda e negociação de cotas do fundo
											não implica, por parte da CVM, garantia de veracidade das
											informações prestadas ou julgamento sobre a qualidade do
											fundo ou de seu administrador.{' '}
											<b>
												<a href="http://www.cvm.gov.br">Clique aqui </a>
												para entrar no site da CVM e confirmar que este é um
												fundo cadastrado.
											</b>{' '}
											Informações através do telefone 0800 025 9666 ou pelo{' '}
											<b>
												<a href="https://cvmweb.cvm.gov.br/swb/default.asp?sg_sistema=sac">
													SAC
												</a>
											</b>
											.
										</p>
										<p className="avisoslinhas">
											Fundos de investimento não contam com garantia do
											administrador do fundo, do gestor da carteira, de qualquer
											mecanismo de seguro ou, ainda, do fundo garantidor de
											créditos - FGC. A rentabilidade obtida no passado não
											representa garantia de rentabilidade futura.
										</p>
										<p className="avisoslinhas">
											Antes de investir no fundo, leia o regulamento e as
											informações constantes do site, em especial, a seção
											fatores de risco.
										</p>
										<p className="avisoslinhas">
											Ao investidor é recomendada a leitura cuidadosa do
											regulamento do fundo de investimento ao aplicar seus
											recursos.
										</p>
										<p className="avisoslinhas">
											Este fundo utiliza estratégias que podem resultar em
											significativas perdas patrimoniais para seus cotistas.
										</p>
										<p className="avisoslinhas">
											Dúvidas, sugestões, reclamações ou para contato com o
											administrador do fundo, mande um e-mail para{' '}
											<a href="mailto:ri.fundoslistados@btgpactual.com">
												ri.fundoslistados@btgpactual.com
											</a>{' '}
											ou ligue para 0800 772 2827 (SAC) / 0800 722 0048
											(Ouvidoria).
										</p>
										<p className="avisoslinhas">
											A presente instituição aderiu ao Código ANBIMA de
											Regulação e Melhores Práticas para Administração de
											Recursos de Terceiros.
										</p>
										<picture className="logomarcaanbima">
											<img
												src="images/fgts-eletrobras-btg-pactual-logo-anbima.jpg"
												alt="Logomarca da ANBIMA"
											/>
										</picture>
									</div>
								</AccordionDetails>
							</Accordion>
						</div>
					</Grid>

					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						className="textoRodape assinatura"
					>
						©2020 BTG Pactual
					</Grid>
				</Grid>
			</Content>
		</Container>
	);
}
