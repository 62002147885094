const shortMonths = [
	'jan',
	'fev',
	'mar',
	'abr',
	'mai',
	'jun',
	'jul',
	'ago',
	'set',
	'out',
	'nov',
	'dez',
];

// const fullMonths = [
// 	'Janeiro',
// 	'Fevereiro',
// 	'Abril',
// 	'Maio',
// 	'Junho',
// 	'Julho',
// 	'Agosto',
// 	'Setembro',
// 	'Outubro',
// 	'Novembro',
// 	'Dezembro',
// ];

export const formatDbDate = (mask, date) => {
	// console.log(date);

	const day = date.substr(0, 2);
	const month = date.substr(3, 2);
	const year = date.substr(6, 4);

	return mask
		.replace('YYYY', year)
		.replace('MM', month)
		.replace('DD', day);
};

export function convertBRLDateToDate(date) {
	let dt = null;

	try {
		if (date) {
			dt = date.replace("'", '').split('/');

			const year = dt[2];
			const month = dt[1] - 1;
			const day = dt[0];

			return new Date(year, month, day);
		} else {
			return new Date();
		}
	} catch (ex) {
		return new Date();
	}
}

export function convertBRLDateToString(date) {
	const dt = date.replace("'", '').split('/');

	const year = dt[2];
	const month = shortMonths[parseInt(dt[1] - 1)];
	const day = dt[0];

	return `${day} ${month} ${year.slice(-2)}`;
}

export const formatDate = (mask, date) => {
	const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

	const month =
		date.getMonth() + 1 < 10
			? `0${date.getMonth() + 1}`
			: `${date.getMonth() + 1}`;

	const year = date.getFullYear();

	return mask
		.replace('YYYY', year)
		.replace('MM', month)
		.replace('DD', day);
};

export const formatCurrencyToBRL = number => {
	return new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	})
		.format(number)
		.replace('R$', '');
};

export const formatPercent = (number, decimalPoint) => {
	return parseFloat(number).toFixed(decimalPoint);
};

export const formatValueToBRL = number => {
	return new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	})
		.format(number)
		.replace('R$', '')
		.replace(',00', '');
};

export function getDateTimeString() {
	const date = new Date();

	return (
		'' +
		date.getFullYear() +
		date.getMonth() +
		date.getDay() +
		date.getHours() +
		date.getMinutes() +
		date.getSeconds()
	);
}

export function smoothScroll(target, duration = 500) {
	const targetElement = document.querySelector(target);

	let height = 0;

	if (document.querySelector('#menu')?.className?.includes('fixed')) {
		height = 120;
	} else {
		height = 165;
	}

	// console.log(target, targetElement, height);

	const targetPosition =
		targetElement.getBoundingClientRect().top + window.pageYOffset - height;
	const startPosition = window.pageYOffset;
	const distance = targetPosition - startPosition;
	let startTime = null;

	function ease(t, b, c, d) {
		t /= d / 2;

		if (t < 1) return (c / 2) * t * t + b;

		t--;

		return (-c / 2) * (t * (t - 2) - 1) + b;
	}

	function animation(currentTime) {
		if (startTime === null) startTime = currentTime;

		const timeElapsed = currentTime - startTime;
		const run = ease(timeElapsed, startPosition, distance, duration);

		window.scrollTo(0, run);

		if (timeElapsed < duration) requestAnimationFrame(animation);

		// console.log('timeElapsed:' + timeElapsed + ' duration: ' + duration);
	}

	requestAnimationFrame(animation);
}
