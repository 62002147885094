import React, { useState, useEffect, useRef, useContext } from 'react';
import { MainContext } from '../../store/context/main';

import { CTAButton, Submenu, SubmenuItem, Tabela } from '../../components';
import { etfService } from '../../service/services/EtfService';
import { Hidden } from '@material-ui/core';

import {
	Title,
	Container,
	RazaoSocial,
	Tributos,
	TributosConteudo,
	TributosBloco,
	TributosCaracteristica,
	TributosTabela,
	TributosLegenda,
	Encargos,
	EncargosConteudo,
	EncargosBloco,
	CTAContainer,
} from './styles';

import { formatDbDate, smoothScroll } from '../../utils/functions';

const getTributo = (list, type) => {
	let result = list.filter(item => item.TipoTibuto === type);

	result = result.map(e => [
		e.TipoInvestidor,
		e.IRAliquota,
		e.ResponsavelRecolhimento,
	]);

	return result;
};

export default () => {
	const [informacoesData, setInformacoesData] = useState([]);
	const [alienacaoData, setAlienacaoData] = useState([]);
	const [integralizacaoData, setIntegralizacaoData] = useState([]);
	const [resgateData, setResgateData] = useState([]);
	const { state, dispatch } = useContext(MainContext);

	const [encargosData, setEncargosData] = useState('');

	const informacoesRef = useRef(null);
	const alienacaoRef = useRef(null);
	const integralizacaoRef = useRef(null);
	const resgateRef = useRef(null);
	const encargosRef = useRef(null);

	const getResult = async () => {
		try {
			const response = await etfService.Caracteristicas();
			const responseData = await response.data;

			const alienacao = getTributo(
				responseData.ObjJsonResultado.Tributos,
				'AlienacaoCotasBolsaValores',
			);
			const integralizacao = getTributo(
				responseData.ObjJsonResultado.Tributos,
				'IntegralizacaoCotasAtivos',
			);
			const resgate = getTributo(
				responseData.ObjJsonResultado.Tributos,
				'ResgateCotasAtivos',
			);

			setInformacoesData(responseData.ObjJsonResultado);
			setAlienacaoData(alienacao);
			setIntegralizacaoData(integralizacao);
			setResgateData(resgate);
			setEncargosData(responseData.ObjJsonResultado.Encargos);
		} catch (err) {
			console.error(
				'Ocorreu um erro ao chamar a API de Composição - Erro: ',
				err,
			);
		}
	};

	useEffect(() => {
		const getData = async () => {
			await getResult();
		};

		getData();

		dispatch({
			type: 'SET_CURRENT_ROUTE',
			currentRoute: 'Caracteristica',
		});
	}, []);

	return (
		<Container>
			<Hidden mdUp>
				<Title>Características</Title>
			</Hidden>
			<Hidden smDown>
				<Submenu>
					<SubmenuItem
						onClick={() => smoothScroll('#informacoes')}
						label="Informações FMP"
					/>
				</Submenu>
			</Hidden>

			<RazaoSocial ref={informacoesRef}>
				<div id="informacoes">
					<h2>Razão social</h2>
					<Tabela
						highlight="Razão social"
						headers={[
							'Razão social',
							'BTG PACTUAL REFERENCE FUNDO MÚTUO DE PRIVATIZAÇÃO FGTS ELETROBRAS',
						]}
					>
						<tr>
							<td>CNPJ</td>
							<td>45.560.774/0001-05</td>
						</tr>
						<tr>
							<td>Aplicação Inicial</td>
							<td>R$ 200,00</td>
						</tr>
						<tr>
							<td>Cotização do Resgate</td>
							<td>D+1</td>
						</tr>
						<tr>
							<td>Liquidação do Resgate</td>
							<td>D+4</td>
						</tr>
						<tr>
							<td>Taxa de administração</td>
							<td>0.20% a.a.</td>
						</tr>
						<tr>
							<td>Gestor</td>
							<td>BTG Pactual Asset Management S.A. DTVM</td>
						</tr>
						<tr>
							<td>Administrador</td>
							<td>BTG Pactual Asset Management S.A. DTVM</td>
						</tr>
						<tr>
							<td>Custodiante</td>
							<td>Banco BTG Pactual S.A.</td>
						</tr>
						<tr>
							<td>Data de início</td>
							<td>14/06/2022</td>
						</tr>
					</Tabela>
				</div>
				<CTAContainer>
					<CTAButton />
				</CTAContainer>
			</RazaoSocial>
		</Container>
	);
};
