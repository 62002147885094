import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { AppBar, Toolbar, Hidden, Divider, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {
	Drawer,
	LogoWhite,
	List,
	Item,
	MenuIcon,
	// LanguageIcon,
} from './styles';

import './header.css';

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
	},
}));

/* const useStyles = makeStyles({
	list: {
		width: 250,
	},
	fullList: {
		width: 'auto',
	},
}); */

const redirectTo = {
	'Como Investir': 'como-investir',
	'Sobre FGTS e FMP': 'sobre-fgts-eletrobras-e-fmp',
	'Visão Geral': 'visao-geral',
	Características: 'caracteristicas',
	Documentos: 'documentos',
};

export default function TemporaryDrawer() {
	const classes = useStyles();
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (anchor, open) => event => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const list = anchor => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === 'top' || anchor === 'bottom',
			})}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<div
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					padding: '15px',
				}}
			>
				<LogoWhite />
			</div>
			<List>
				{[
					'Como Investir',
					'Sobre FGTS e FMP',
					'Visão Geral',
					'Características',
					'Documentos',
				].map(text => (
					<Link to={redirectTo[text]} key={text}>
						<Item>{text}</Item>
					</Link>
				))}
			</List>
			<Divider />
			<List>
				{/*{['Inglês', 'Português'].map(
					(text = ( 
						<Item key={tex}>
							<LanguageIcon />
							<spn>{text}</spn>
						</Item>
					)),
				)} */}
			</List>
		</div>
	);

	return (
		<div className={classes.root} id="header">
			<AppBar position="static" color="inherit" className="topBar">
				<Toolbar>
					<Hidden mdUp>
						<div className="botaomenu">
							{['left'].map(anchor => (
								<React.Fragment key={anchor}>
									<Button onClick={toggleDrawer(anchor, true)}>
										<MenuIcon />
									</Button>
									<Drawer
										anchor={anchor}
										open={state[anchor]}
										onClose={toggleDrawer(anchor, false)}
									>
										{list(anchor)}
									</Drawer>
								</React.Fragment>
							))}
						</div>
					</Hidden>

					<picture className="logomarca">
						<source
							media="(max-width: 768px)"
							srcSet="images/btg-pactual-logomarca-s.png"
						/>
						<source
							media="(min-width: 769px)"
							srcSet="images/btg-pactual-logomarca.png"
						/>
						<img
							src="images/btg-pactual-logomarca.png"
							alt="Logomarca BTG Pactual"
						/>
					</picture>
				</Toolbar>
			</AppBar>
		</div>
	);
}
