import React, { useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { MainContext } from '../../store/context/main';
import { Container } from './styles';

const getActiveMenu = {
	'sobre-fgts-eletrobras-e-fmp': 'SOBRE FGTS E FMP',
	'visao-geral': 'VISÃO GERAL',
	'como-investir': 'COMO INVESTIR',
	caracteristicas: 'CARACTERÍSTICAS',
	documentos: 'DOCUMENTOS',
};

function Aba({ to, label, onClick, active }) {
	const { state } = useContext(MainContext);
	const location = useLocation();

	const getClassName = () => {
		let result = '';

		if (active) {
			result += 'active';
		} else {
			const menu = location.pathname.replace('/', '');

			if (menu) {
				if (label === getActiveMenu[menu]) {
					result += 'active';
				}
			}
		}
		if (state.fixedMenu) result += ' fixed';

		return result;
	};

	return (
		<Link to={to}>
			<Container onClick={onClick} className={getClassName()}>
				<span>{label}</span>
			</Container>
		</Link>
	);
}

export default Aba;
