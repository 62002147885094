import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	background-color: #ededed;
	padding: 25px 0;

	.MuiPaper-elevation1 {
		box-shadow: none !important;
	}

	.MuiPaper-root {
		margin-top: 20px !important;
		background-color: transparent !important;
		border: 1px solid #418fde !important;
	}

	.MuiTypography-body1 {
		font-size: 14px !important;
	}

	.MuiAccordionSummary-content {
		display: unset !important;
		flex-grow: unset !important;
		color: #418fde !important;
	}

	.MuiIconButton-root {
		color: #418fde !important;
	}

	@media (max-width: 800px) {
		padding: 10px 0;
	}
`;

export const Content = styled.div`
	position: relative;
	margin: auto;

	width: 90%;
	max-width: 1141px;

	.textoRodape {
		color: var(--dark-blue);
		font-size: 0.55em;
		width: 100%;
		line-height: 1.5em;
	}

	.assinatura {
		margin: 15px 0 0 0 !important;
	}

	.picture.logomarcaFooter img {
		width: 120px;
	}

	.avisoslinhas {
		text-align: left;
		margin-bottom: 20px;
		font-size: 14px;
		line-height: 1.5em;

		a {
			:hover {
				text-decoration: underline;
				color: var(--light-blue);
			}
		}
	}

	.avisoslinhas a {
		color: #418fde !important;
	}

	.logomarcaanbima img {
		width: 260px;
	}

	@media (min-width: 800px) {
		.enderecoRodape {
			text-align: left;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
		.textoRodape {
			font-size: 0.7em;
			width: 50%;
		}

		.logomarcaFooter {
			display: flex;
		}
	}

	@media (max-width: 800px) {
		picture.logomarcaFooter img {
			display: none;
		}
	}
`;
