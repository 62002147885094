import React, { useState, useEffect, useRef, useContext } from 'react';
import { Hidden } from '@material-ui/core';
import { MainContext } from '../../store/context/main';
import { Submenu, SubmenuItem } from '../../components';

import { smoothScroll } from '../../utils/functions';

import ComoInvestirTexts from './texts';

import Smart1 from '../../assets/imgs/smart_01.png';
import Smart2 from '../../assets/imgs/smart_02.png';
import Smart3 from '../../assets/imgs/smart_03.png';
import Smart4 from '../../assets/imgs/smart_04.png';
import Smart5 from '../../assets/imgs/smart_05.png';
import Smart6 from '../../assets/imgs/smart_06.png';
import Smart7 from '../../assets/imgs/smart_07.png';
import SmartDigital from '../../assets/imgs/smart_digital.png';
import Trofeu from '../../assets/imgs/icon_trofeu.png';
import Google from '../../assets/imgs/googleplay.png';
import Apple from '../../assets/imgs/apple.png';
import AutorizarBanco from '../../assets/imgs/autorizar_banco.jpg';
import Calendar from '../../assets/imgs/calendar-days-solid.svg';

import {
	Title,
	Container,
	ComoInvestir,
	ComoInvestirBanner,
	ComoInvestirConteudo,
	AcessItem,
	Line,
	CTAContainer,
	LogoFGTS,
	EscolhaBTG,
	EscolhaBTGConteudo,
	InformationBlock,
	Simulador,
	SimuladorConteudo,
	Smarts,
	SmartsConteudo,
	Finalizado,
	FinalizadoConteudo,
	SoliciteReserva,
	SoliciteReservaConteudo,
	InformationItem,
	InformationItemContent,
	InformationItemDetail,
	NumberCircle,
	SmartsImg,
	FinalizadoImg,
	SoliciteReservaTitulo,
	SoliciteReservaMain,
	SoliciteReservaImg,
	NumberCircleReserva,
	ItemReserva,
	ItemReservaTitulo,
	ItemReservaConcluido,
	Atencao,
	ItemReservaImg,
	ItemSmarts,
	NumberCircleSmarts,
	AcessItemSmarts,
} from './styles';
export default () => {
	const [result, setResult] = useState('');
	const { state, dispatch } = useContext(MainContext);

	const escolhaBTGFGTS = useRef(null);

	const getResult = async () => {
		try {
			throw new Error(); //force mock

			// const response = await etfService.Geral();
			// const responseData = await response.data;

			// setResult(responseData.ObjJsonResultado);
		} catch (err) {
			setResult(ComoInvestirTexts);

			// console.error('Ocorreu um erro ao chamar a API Geral - Erro: ', err);
		}
	};

	useEffect(() => {
		const getData = async () => {
			await getResult();
		};

		getData();

		dispatch({
			type: 'SET_CURRENT_ROUTE',
			currentRoute: 'Como Investir',
		});
	}, []);

	return (
		<Container>
			<Hidden mdUp>
				<Title>Como investir</Title>
			</Hidden>
			<Hidden smDown>
				<Submenu>
					<SubmenuItem
						onClick={() => smoothScroll('#escolhaBTGFGTS')}
						label="Escolha o BTG Pactual no app do FGTS"
					/>
					<SubmenuItem
						onClick={() => smoothScroll('#solicitereservabtg')}
						label="Solicite sua reserva pelo BTG Investimentos"
					/>
				</Submenu>
			</Hidden>

			<ComoInvestir>
				<ComoInvestirConteudo>
					<p id="como investir">
						Saiba como investir no FMP Eletrobras <br /> do BTG Pactual
					</p>
				</ComoInvestirConteudo>
				<LogoFGTS />
			</ComoInvestir>
			<ComoInvestirBanner>
				<hr />
				<ul>
					<li>
						<span style={{ backgroundColor: '#FFBB38' }}>
							<img src={Calendar}></img>
						</span>
						<p>
							<strong>
								Cronograma <br /> do FMP
							</strong>
						</p>
					</li>
					<li>
						<span>
							<img src={Calendar}></img>
						</span>
						<p>
							<strong>27/05 a 08/06</strong>
						</p>
						<p style={{ fontSize: '12px' }}>Autorize o BTG no app do FGTS</p>
					</li>
					<li>
						<span>
							<img src={Calendar}></img>
						</span>
						<p>
							<strong>03/06 a 08/06</strong>
						</p>
						<p style={{ fontSize: '12px' }}>Solicitação da reserva</p>
					</li>
					<li>
						<span>
							<img src={Calendar}></img>
						</span>
						<p>
							<strong>08/06</strong>
						</p>
						<p style={{ fontSize: '12px' }}>Encerramento das reservas às 12h</p>
					</li>
				</ul>
			</ComoInvestirBanner>
			<EscolhaBTG id="escolhaBTGFGTS">
				<img src={Smart1} />
				<EscolhaBTGConteudo ref={escolhaBTGFGTS}>
					<h2 id="politica">escolha o btg pactual no app do fgts</h2>
					<p>{result.Escolha?.Politicainvestimento}</p>
					<br />
					<AcessItem>
						<NumberCircle>
							<span>1</span>
						</NumberCircle>
						<p>
							Acesse o app FGTS em seu celular.
							<br />
							<p>
								Não tem acesso ao app FGTS?{' '}
								<a
									href="https://www.caixa.gov.br/atendimento/aplicativos/fgts/Paginas/default.aspx "
									target="_blank"
								>
									Clique aqui
								</a>{' '}
								e saiba como instalar e se cadastrar!
							</p>
						</p>
					</AcessItem>
					<br />
					<Line />
					<AcessItem>
						<NumberCircle>
							<span>2</span>
						</NumberCircle>
						<p>
							Na tela principal, entre no card "
							<strong>Autorizar bancos a consultarem seu FGTS</strong>".
						</p>
					</AcessItem>
					<img src={AutorizarBanco} />
				</EscolhaBTGConteudo>
			</EscolhaBTG>
			<Simulador>
				<SimuladorConteudo>
					<AcessItem>
						<NumberCircle>
							<span>3</span>
						</NumberCircle>
						<p>{result.Escolha?.Acesso3}</p>
					</AcessItem>
					<InformationBlock>
						<InformationItem>
							<InformationItemContent>
								<h3>SIMULADOR DE APLICAÇÃO NO FMP - FGTS</h3>
								<p>{result.Escolha?.Simulador1}</p>
							</InformationItemContent>
							<InformationItemDetail>
								<p>{result.Escolha?.Simula1}</p>
							</InformationItemDetail>
						</InformationItem>
						<InformationItem>
							<InformationItemContent>
								<h3>AUTORIZAÇÃO DE CONSULTA ÀS INFORMAÇÕES DO FGTS</h3>
								<p>{result.Escolha?.Simulador2}</p>
							</InformationItemContent>
							<InformationItemDetail>
								<p>{result.Escolha?.Simula2}</p>
							</InformationItemDetail>
						</InformationItem>
					</InformationBlock>
				</SimuladorConteudo>
				<img src={Smart2} />
			</Simulador>
			<Smarts>
				<SmartsImg>
					<img src={Smart3} />
					<img src={Smart4} />
					<img src={Smart5} />
					<img src={Smart6} />
				</SmartsImg>
				<SmartsConteudo>
					<ItemSmarts>
						<AcessItemSmarts>
							<NumberCircleSmarts>
								<span>4</span>
							</NumberCircleSmarts>
							<p>
								Depois de clicar em{' '}
								<strong>
									“Autorização de consulta às informações do FGTS”
								</strong>
								, vá até a opção{' '}
								<strong>
									“Aplicação nos fundos mútuos de privatização FGTS”
								</strong>
								, selecione o fundo <strong>“FMP ELETROBRAS”</strong> e clique
								em “Continuar”.
							</p>
						</AcessItemSmarts>
					</ItemSmarts>
					<ItemSmarts>
						<AcessItemSmarts>
							<NumberCircleSmarts>
								<span>5</span>
							</NumberCircleSmarts>
							<p>{result.Escolha?.Smarts2}</p>
						</AcessItemSmarts>
					</ItemSmarts>
					<ItemSmarts>
						<AcessItemSmarts>
							<NumberCircleSmarts>
								<span>6</span>
							</NumberCircleSmarts>
							<p>{result.Escolha?.Smarts3}</p>
						</AcessItemSmarts>
					</ItemSmarts>
				</SmartsConteudo>
			</Smarts>
			<Finalizado>
				<FinalizadoImg>
					<img src={Smart7} />
				</FinalizadoImg>
				<FinalizadoConteudo>
					<div>
						<img src={Trofeu} />
						<p>{result.Escolha?.Finalizado}</p>
					</div>
					<p>Agora, é necessário solicitar a sua reserva pra gente!</p>
				</FinalizadoConteudo>
			</Finalizado>
			<SoliciteReserva id="solicitereservabtg">
				<SoliciteReservaTitulo>
					<h2 id="politica">SOLICITE SUA RESERVA PELO BTG INVESTIMENTOS</h2>
					<p style={{ color: '#FFF' }}>{result.SoliciteReserva?.Subtitulo}</p>
				</SoliciteReservaTitulo>
				<SoliciteReservaMain>
					<SoliciteReservaConteudo>
						<ItemReserva>
							<AcessItem>
								<NumberCircleReserva>
									<span>1</span>
								</NumberCircleReserva>
								<ItemReservaTitulo>
									<p style={{ color: '#FFF', lineHeight: '1.5' }}>
										Acesse a sua conta do BTG Investimentos.
									</p>
									<p style={{ color: '#FFF', fontSize: '14px' }}>
										{result.SoliciteReserva?.Passo1}
									</p>
								</ItemReservaTitulo>
							</AcessItem>
						</ItemReserva>
						<ItemReservaImg>
							<a
								href="https://itunes.apple.com/br/app/btg-pactual-digital/id1041958375"
								target="_blank"
							>
								<img src={Apple} />
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.btg.pactual.digital.mobile"
								target="_blank"
							>
								<img src={Google} />
							</a>
						</ItemReservaImg>
						<ItemReserva>
							<AcessItem>
								<NumberCircleReserva>
									<span>2</span>
								</NumberCircleReserva>
								<ItemReservaTitulo>
									<p style={{ color: '#FFF' }}>
										Entre em contato com seu assessor e informe qual o valor do
										seu FGTS que você deseja aplicar no FMP Eletrobras.
									</p>
									<p style={{ color: '#FFF', fontSize: '14px' }}>
										{result.SoliciteReserva?.Passo2}
									</p>
								</ItemReservaTitulo>
							</AcessItem>
						</ItemReserva>
						<ItemReserva>
							<AcessItem>
								<NumberCircleReserva>
									<span>3</span>
								</NumberCircleReserva>
								<p style={{ color: '#FFF' }}>
									{result.SoliciteReserva?.Passo3}
								</p>
							</AcessItem>
						</ItemReserva>
						<ItemReserva>
							<ItemReservaConcluido>
								<img src={Trofeu} />
								<p>{result.SoliciteReserva?.Concluido}</p>
							</ItemReservaConcluido>
						</ItemReserva>
					</SoliciteReservaConteudo>
					<SoliciteReservaImg>
						<img src={SmartDigital} />
					</SoliciteReservaImg>
				</SoliciteReservaMain>
			</SoliciteReserva>
			<Atencao>
				<p>
					{result.Atencao?.Aviso}
					{result.Atencao?.Data}
				</p>
			</Atencao>
		</Container>
	);
};
