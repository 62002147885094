import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-bottom: 5px solid var(--white);

	padding: 8px 14px 14px;

	width: 140px;
	height: 85px;

	background-color: var(--dark-blue);
	color: var(--white);

	transition: all ease 0.2s;

	> span {
		font-size: 12px;
		text-align: center;
	}

	@media (max-width: 768px) {
		> span {
			font-size: 0.6em;
		}
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		width: unset;
	}

	:hover {
		background-color: var(--light-blue);
	}

	&.active {
		height: 95px;
		font-weight: bold;

		border-top: 10px solid var(--light-blue);
		margin: 0px 15px 0px;
		padding-bottom: 25px;

		background-color: var(--white);
		color: var(--dark-blue);

		transition: all ease 0.2s;

		/* animation: bganimation 0.25s ease-out;

		@keyframes bganimation {
			from {
				background-color: var(--light-blue);
			}
			to {
				background-color: var(--white);
			}
		} */

		&.fixed {
			height: 40px;
			padding: 0px 15px 5px;
		}
	}

	:not(.active) {
		margin: 0px 15px 10px;

		&.fixed {
			height: 33px;
			padding: 0px 15px 5px;
		}
	}
`;
