import styled from 'styled-components';

export const Button = styled.button`
	background-color: ${props => (props.primary ? '#418fde' : '#fff')};
	color: ${props => (props.primary ? '#fff' : '#001e62')};
	display: flex;
	align-items: center;
	font-weight: 700;
	justify-content: center;
	text-align: center;
	padding: 5px;
	width: 100%;
	height: 3rem;
	font-size: 1.15em;
	border: 2px solid #001e62;
	border-radius: 3px;
	&:hover {
		background-color: ${props => (props.primary ? '#001e62' : '#b1cae3')};
	}
	@media (max-width: 768px) {
		width: 100%;
		padding: 1px;
		font-size: 1em;
	}
`;
