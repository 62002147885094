import styled from 'styled-components';
import DocumentosImg from '../../assets/imgs/documentos.jpg';

import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';

export const Container = styled.div`
	h2 {
		text-align: left;
	}
`;
export const Title = styled.h1`
	background-color: white;
	color: #4d4d4d;
	font-size: 1em;
	font-weight: 100;
	text-transform: uppercase;
	padding: 2px 0 11px 0;
	-webkit-box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	-moz-box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	display: inline-block;
	width: 100%;
`;
export const BoxContainer = styled.div`
	width: 100%;
	min-height: 600px;

	background-image: url(${DocumentosImg});
	background-size: cover;
	background-position: center;

	@media (max-width: 780px) {
		min-height: 1000px;
	}
`;
export const Box = styled.div`
	width: 100%;

	display: flex;
	justify-content: center;
`;
export const Content = styled.div`
	width: 90%;
	max-width: 1141px;

	@media (max-width: 780px) {
		width: 100%;
	}

	&.align-right {
		display: flex;
		justify-content: flex-end;
	}
`;
export const Block = styled.div`
	display: flex;
	flex-direction: column;

	width: 50%;
	padding: 45px;
	text-align: initial;

	background-color: var(--dark-blue);

	> h2 {
		color: var(--white);
	}

	> button {
		margin-top: 15px;

		display: flex;
		align-items: center;

		cursor: pointer;

		> span {
			margin-left: 5px;
			font-size: 18px;

			color: var(--white);

			transition: all ease 0.2s;
			text-align: left;

			:hover {
				color: var(--light-blue);
			}
		}
	}

	@media (max-width: 780px) {
		width: 100%;
		padding: 30px 15px;

		span {
			font-size: 1.05em !important;
			text-align: left;
			line-height: 1.2em;
		}

		> h2 {
			margin-bottom: 15px;
		}
	}

	&.gray {
		background-color: var(--gray-bg);

		> h2 {
			color: var(--dark-blue);
		}

		> button {
			> span {
				color: var(--dark-blue);

				:hover {
					color: var(--light-blue);
				}
			}
		}
	}
`;
export const Icon = styled(LibraryBooksOutlinedIcon)`
	fill: var(--light-blue);
`;

export const CTAContainer = styled.div`
	margin: auto;
	margin-top: 2rem !important;
	width: 40%;
	@media (max-width: 768px) {
		width: 90%;
	}
`;
