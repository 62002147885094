export default {
	Perfil: {
		Politicainvestimento:
			'Para atingir o seu objetivo, o Fundo investirá no mínimo 90% de seu patrimônio em ações ordinárias da Eletrobras e no máximo 10% de sua carteira em títulos públicos federais de renda fixa.',
		PublicoAlvo:
			'O Fundo, observada a legislação e regulamentação aplicáveis, é destinado a investidores em geral, residentes e não residentes, que aceitem todos os riscos inerentes ao investimento no Fundo e busquem retorno de rentabilidade condizente com o objetivo do produto.',
	},
	RegraMovimentacao: {
		MercadoSecundario:
			'É possível realizar investimento em lotes a partir de 1 cota no mercado secundário, em horários determinados pela B3.',
		MercadoPrimario:
			'É possível realizar investimento em lotes a partir de 50.000	cotas ou em múltiplos de lotes mínimos de 50.000 cotas, que	só poderão ser emitidos ou resgatados se entregues de acordo com uma ordem submetida por um Agente Autorizado e mediante a entrega de uma cesta. A composição da cesta deve ter, no	mínimo, 95% do seu valor representado por ações do Índice e, no máximo, 5% do seu valor representado	por Investimentos Permitidos e/ou Valores em Dinheiro. Ordens de Integralização e Resgate a um Agente Autorizado serão recebidas e processadas em até 15 minutos antes do encerramento do pregão regular da B3. A cota de	integralização e resgate utilizada será a de D0 da solicitação.',
	},
};
