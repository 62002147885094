import styled from 'styled-components';
import { Drawer as DrawerComponent } from '@material-ui/core';
import { Menu, LanguageOutlined } from '@material-ui/icons';
import { ReactComponent as BTGLogoWhite } from '../../assets/imgs/btg-pactual-logo-white.svg';

export const Drawer = styled(DrawerComponent)`
	.MuiPaper-root {
		background-color: var(--dark-blue);
	}
`;

export const List = styled.ul`
	padding: 20px 0px;
	border-top: 1px solid var(--light-blue);
`;

export const Item = styled.li`
	display: flex;
	align-items: center;

	padding: 15px;
	text-decoration: none;

	color: var(--white);
	font-weight: bold;

	transition: all ease 0.2s;

	> span {
		margin-left: 7px;
		color: inherit;
	}

	> a {
		color: inherit;
	}

	:hover,
	:focus {
		color: var(--light-blue);
	}
`;

export const LogoWhite = styled(BTGLogoWhite)``;

export const MenuIcon = styled(Menu)``;

export const LanguageIcon = styled(LanguageOutlined)``;
