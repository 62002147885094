import styled from 'styled-components';
import RazaoSocialImg from '../../assets/imgs/razaosocialfull.jpg';

export const Container = styled.div``;

export const Title = styled.h1`
	background-color: white;
	color: #4d4d4d;
	font-size: 1em;
	font-weight: 100;
	text-transform: uppercase;
	padding: 2px 0 11px 0;
	-webkit-box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	-moz-box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	display: inline-block;
	width: 100%;
`;

export const RazaoSocial = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;
	min-height: 625px;
	height: 80vh;
	background-image: url('${RazaoSocialImg}');
	background-size: cover;
	background-position: center;

	h2 {
		display: none;
	}

	> div {
		width: 40vw;
		min-width: 555px;
		max-width: 600px;
		min-height: 540px;
		max-height: 70vh;
		margin-right: 400px;
		background-color: var(--white);

		overflow: overlay;
	}

	@media (max-width: 768px) {
		height: unset;
		padding: 15px;

		> div {
			margin-right: unset;

			width: unset;
			min-width: unset;
			max-height: unset;

			overflow: scroll;

			> table {
				min-width: 500px;
			}
		}
	}
`;

export const Tributos = styled.div`
	display: flex;
	justify-content: center;

	width: 100%;
	min-height: 1400px;

	background-image: url('/asset-management/fgts-eletrobras/images/portal-btg-pactual-caracteristicas-tributos.jpg');
	background-size: cover;
	background-position: center;

	margin: 60px 0 30px 0;

	@media (max-width: 768px) {
		margin: unset;
		min-height: unset;
	}
`;

export const TributosConteudo = styled.div`
	display: flex;
	justify-content: center;

	width: 90%;
	max-width: 1141px;

	h2 {
		margin-bottom: 30px;
		color: var(--white);
	}

	h2,
	h3,
	p {
		color: var(--white);
		text-align: left;
	}

	h3 {
		margin-bottom: 20px;
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const TributosBloco = styled.div`
	width: 950px;
	min-height: 1500px;

	padding: 80px 100px;
	margin: -30px 0 35px 0;

	background-color: var(--light-blue);

	@media (max-width: 768px) {
		width: 100%;
		height: unset;
		margin: unset;
		padding: 30px;

		/* > div {
			margin-right: unset;
			width: unset;
			max-height: unset;

			overflow: scroll;

			table {
				min-width: 700px;
			}
		} */

		table {
			min-width: 700px;
		}
	}
`;

export const TributosCaracteristica = styled.div`
	margin-top: 60px;

	@media (max-width: 768px) {
		margin-right: unset;
		width: unset;
		max-height: unset;

		overflow: overlay;

		/* table {
			min-width: 700px;
		} */
	}
`;

export const TributosTabela = styled.div`
	width: 100%;
	/* min-height: 300px;
	max-height: 300px; */
	background-color: white;
	margin-bottom: 15px;

	overflow: overlay;
`;

export const TributosLegenda = styled.p`
	font-size: 0.8em;

	> span {
		display: block;
	}

	> strong {
		display: block;
		font-weight: normal;
		margin-left: 60px;
	}
`;

export const Encargos = styled.div`
	display: flex;
	justify-content: center;

	width: 100%;
`;

export const EncargosConteudo = styled.div`
	width: 90%;
	max-width: 1141px;
	margin: 30px 0px 40px 0px;

	> h2 {
		text-align: left;
		margin-bottom: 30px;
		color: var(--light-blue);
	}

	> p {
		text-align: left;
		line-height: 1.5em;
	}

	@media (max-width: 768px) {
		max-width: unset;
		margin: unset;
		padding: 30px;
	}
`;

export const EncargosBloco = styled.div`
	width: 80%;

	margin: 0 auto;
	margin-top: 25px;

	p {
		margin-top: 15px;
		font-size: 0.8em;
		text-align: left;
		line-height: 1.5em;
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;
export const CTAContainer = styled.div`
	margin-top: 3rem;
	min-height: auto !important;
	max-height: auto !important;
	background-color: transparent !important;
	@media (max-width: 768px) {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
`;
