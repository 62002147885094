import React, { useContext } from 'react';
import { Button } from './styles';
import { Link } from 'react-router-dom';
import { MainContext } from '../../store/context/main';

const CTAButton = props => {
	const { dispatch } = useContext(MainContext);
	const handleClick = () => {
		dispatch({
			type: 'SET_SELECTED_MENU',
			selectedMenu: 'menu4',
		});
	};
	return (
		<Link to="">
			<Button {...props} as="a" onClick={handleClick}>
				Clique aqui para investir no FMP Eletrobras do BTG Pactual
			</Button>
		</Link>
	);
};

export default CTAButton;
