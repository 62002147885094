import axios from 'axios';
import Identity from '../../config/mocks/identity';

// axios.create({ withCredentials: true });

const etfService = {};

etfService.Documento = () => axios.get(`/Documento/`,{
	params: {
		ID: Identity.ID()
	},
});

etfService.Geral = () => axios.get(`/Geral/`,{
	params: {
		ID: Identity.ID()
	},
});

etfService.Caracteristicas = () => axios.get('/Caracteristica/',{
	params: {
		ID: Identity.ID()
	},
});

export { etfService };
