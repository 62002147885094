import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	:root {
		--black: #000;
		--white: #fff;
		--gray: #e8e8e8;
		--gray-bg: #e7e9e8;
		--table-text: #424242;
		--dark-blue: #001E62;
		--light-blue: #418FDE;
		
	}
`;
