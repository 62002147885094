import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import ObjetivoImg from '../../assets/imgs/objetivo.jpg';
import PublicoImg from '../../assets/imgs/publicoalvo.jpg';

export const Title = styled.h1`
	background-color: white;
	color: #4d4d4d;
	font-size: 1em;
	font-weight: 100;
	text-transform: uppercase;
	padding: 2px 0 11px 0;
	-webkit-box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	-moz-box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	display: inline-block;
	width: 100%;
`;

export const Container = styled.div`
	h2,
	h3 {
		margin-bottom: 10px;
	}

	h2,
	h3,
	p {
		text-align: left;
	}
`;

export const Objetivo = styled.div`
	display: flex;
	justify-content: center;

	width: 100%;
	min-height: 300px;
	background-image: url(${ObjetivoImg});
	background-size: cover;
	background-position: center;

	@media (max-width: 768px) {
		min-height: unset;
	}
`;
export const ObjetivoConteudo = styled.div`
	display: flex;
	justify-content: flex-end;

	width: 90%;
	max-width: 1141px;

	> div {
		width: 42%;

		margin: 80px;
		padding: 40px 50px;

		background-color: var(--dark-blue);

		> h2 {
			color: var(--light-blue);
		}

		> p {
			line-height: 1.5em;
			color: var(--white);
		}

		@media (max-width: 768px) {
			width: unset;
			margin: unset;
			padding: 30px;
		}
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;
export const Politica = styled.div`
	display: flex;
	justify-content: center;

	width: 100%;
`;
export const PoliticaConteudo = styled.div`
	min-height: 200px;
	padding: 100px 0 80px 0;

	width: 90%;
	max-width: 1141px;

	> h2 {
		color: var(--light-blue);
	}

	> p {
		line-height: 1.5em;

		> strong {
			color: var(--dark-blue);
		}
	}

	@media (max-width: 768px) {
		width: 100%;
		padding: 30px;
	}
`;
export const Publico = styled.div`
	width: 100%;
	min-height: 300px;
	background-image: linear-gradient(#022980, #001e62);
	display: flex;
	justify-content: center;
	position: relative;

	@media (max-width: 768px) {
		min-height: unset;
	}
`;
export const PublicoConteudo = styled.div`
	min-height: 200px;
	height: 420px;

	width: 90%;
	max-width: 1141px;

	> div {
		width: 48%;
		/* height: 100%; */
		padding: 50px 40px 20px 40px;
		margin: -25px 0 35px 0;
		display: block;

		background-color: var(--light-blue);

		> h2 {
			color: var(--white);
		}

		> p {
			line-height: 1.5em;
			color: var(--white);
			font-size: 16px;
		}

		> div {
			width: 100%;

			margin: 20px 0;
			padding: 20px 30px;

			color: var(--white);
			border: 1px solid var(--white);

			> strong {
				cursor: pointer;
				font-weight: normal;
				text-decoration: underline;
			}
		}

		@media (max-width: 768px) {
			width: unset;
			margin: unset;
			padding: 30px;
		}
	}

	@media (max-width: 768px) {
		width: 100%;
		height: 100%;
	}
`;
export const PublicoImagem = styled.div`
	width: 50%;
	background-image: url(${PublicoImg});
	background-size: contain;
	background-position: center right;
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;

	@media (max-width: 768px) {
		display: none;
	}
`;

export const PublicoText = styled.div`
	text-align: left;
	height: 80%;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> p {
		padding-top: 24px;
	}
`;

export const BlockText = styled.p`
	margin-top: 20px;
	padding: 16px;
	font-size: 16px;
	text-align: center !important;
`;

export const Regras = styled.div`
	display: flex;
	justify-content: center;

	width: 100%;
	min-height: 300px;
`;
export const RegrasConteudo = styled.div`
	min-height: 200px;
	width: 90%;
	max-width: 1141px;

	padding: 80px 0 70px 0;

	> h2 {
		color: var(--dark-blue);
	}

	.regrasimg {
		max-width: 77px;
	}

	> div:last-child {
		width: 100%;

		margin: 25px 0;
		padding: 20px 30px;

		background-color: var(--light-blue);
		color: var(--white);

		> strong {
			cursor: pointer;
			font-weight: normal;
			text-decoration: underline;
		}
	}

	@media (max-width: 768px) {
		width: unset;
		padding: 30px;
	}
`;

export const RegrasImg = styled(Grid)`
	margin-top: 60px !important;

	@media (max-width: 768px) {
		margin-top: 30px !important;
	}
`;

export const RegrasTexto = styled(Grid)`
	margin-top: 30px !important;
	padding: 0 29px 0 30px;

	> h3 {
		color: var(--light-blue);
	}

	p {
		line-height: 1.5em;
		font-size: 14px;
	}

	@media (max-width: 768px) {
		padding: unset;
	}
`;
export const CTAContainer = styled.div`
	width: 100%;
	font-size: 0.8rem;
	border: none !important;
	padding: 0 !important;
`;
