declare const window: any;

const appConfig = {
	LOCAL: {
		//API_URL: `https://localhost:44342/api`,
		API_URL: 'http://portal-etf.dev.funds.k8s.container.btgpactual.net/api',
		//API_URL: 'http://portal-etf.dev.funds.k8s.container.btgpactual.net/api',
	},
	DEV: {
		API_URL: `${window.location.protocol}//${'portal-etf.dev.funds.k8s.container.btgpactual.net'}/api`,
	},
	UAT: {
		API_URL: 'https://site.uat.btg.cloud/etf/api'
	},
	PROD: {
		API_URL: window.location.hostname ===
		'portal-fgts-eletrobras.prod.funds.k8s.container.btgpactual.net'
			? `${window.location.protocol}//${'portal-etf.prod.funds.k8s.container.btgpactual.net'}/api`
			: `https://www.btgpactual.com/etf/api`,
	},
};

export default function getConfiguration() {
	console.log(`hostname: ${window.location.hostname}`);
	switch (window.ENVIRONMENT) {
		case 'LOCAL':
			return appConfig.LOCAL;
		case 'DEV':
			return appConfig.DEV;
		case 'UAT':
			return appConfig.UAT;
		case 'PROD':
			return appConfig.PROD;
		default:
			break;
	}
}
