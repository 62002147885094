import React, { createContext, useReducer } from 'react';

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_SELECTED_MENU':
			return { ...state, selectedMenu: action.selectedMenu };
		case 'SET_CURRENT_ROUTE':
			return { ...state, currentRoute: action.currentRoute };
		case 'SET_FIXED_MENU':
			return { ...state, fixedMenu: action.fixedMenu };
		default:
			return state;
	}
};

const initialState = {
	selectedMenu: null,
	fixedMenu: false,
	currentRoute: '',
};

const MainContext = createContext(initialState);

function MainProvider(props) {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<MainContext.Provider value={{ state, dispatch }}>
			{props.children}
		</MainContext.Provider>
	);
}
export { MainContext, MainProvider };
