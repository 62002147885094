import styled from 'styled-components';

export const TableContainer = styled.div`
	flex: 1;
	height: 100%;

	overflow: overlay;

	::-webkit-scrollbar {
		background-color: var(--white);
		width: 5px;
		height: 5px;
	}

	::-webkit-scrollbar-thumb {
		background-color: var(--dark-blue);
		border-radius: 2px;
	}

	::-webkit-scrollbar-button:vertical:decrement,
	::-webkit-scrollbar-button:vertical:increment {
		height: 0px;
	}

	::-webkit-scrollbar-track {
		background-color: var(--white);
	}
`;
export const Table = styled.table`
	width: 100%;
	text-align: left;
	font-size: 13px;
`;
export const Head = styled.thead`
	background-color: var(--dark-blue);
	color: var(--white);

	> tr {
		> th {
			padding: 25px 0px 25px 25px;
			border-right: 1.5px solid var(--white);
			text-transform: uppercase;
			font-weight: normal;

			&.highlight {
				background-color: var(--light-blue);
			}
		}
		> th:last-child {
			border: 0;
			padding-right: 10px;
		}
	}
`;
export const Body = styled.tbody`
	> tr {
		border-bottom: 1px solid var(--gray);

		> td {
			padding: 15px 0px 15px 25px;
			color: var(--table-text);
		}
	}
`;
