import React, { useState } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Tooltip from '@material-ui/core/Tooltip';

import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem'
import ClearAllIcon from '@material-ui/icons/ClearAll';

import {
	Container,
	TableContainer,
	Table,
	Head,
	Body,
	Options,
	Filtro,
	Tabs,
	Tab,
	Items,
	Item,
	Download,
	DownloadIcon,
	LoadingContainer,
	HoraAtualizacao
} from './styles';

import { months } from '../../utils/objects';
import { Button } from '@material-ui/core';

const actualYear = new Date().getFullYear();
const actualMonth = parseInt(new Date().getMonth() + 1);

const startingYear = 2021;
const startingMonth = 9;


const TabelaCalendario = ({
	variant,
	reference,
	headers,
	data,
	buttonText,
	customHeader,
	customData,
	onClick,
	hideOptions,
	hideFilterCalendar,
	hideFilterYear,
	hideFilterMonth,
	hideDownloadButton,
	onDownloadClick,
	negritoLastFlag,
	anosReferencia,
	hidePagination,
	rowsPerPageInit,
	textColorPagination,
	backgroudColorPagination,
	showUpdateDate,
	updateDate
}) => {
	const [activeYear, setActiveYear] = useState(actualYear);
	const [activeMonth, setActiveMonth] = useState(actualMonth);
	const [hidingPagination, setHidingPagination] = useState(hidePagination);
	const [messagePagination, setMessagePagination] = useState("Ver tudo");

	const [page, setPage] = React.useState(1);
  	const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageInit);

	var years_control = 0;

	const useStyles = makeStyles({
		selected: {
			color: "white !important",
		  	backgroundColor: backgroudColorPagination + " !important"
		},
		root: {
			color: textColorPagination,
		},
	  });

	  const classes = useStyles();


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	
	const hideShowPagination = (event) => {
		
		if(messagePagination === "Ver tudo")
			setMessagePagination("Ver menos")
		else
			setMessagePagination("Ver tudo")
		
		if(rowsPerPage === rowsPerPageInit)
			setRowsPerPage(10000);
		else
			setRowsPerPage(rowsPerPageInit);
		
		setPage(1);

		setHidingPagination(!hidingPagination);
	}

	const handleYearClick = event => {
		if (event.target.id) {
			const items = Array.from(
				document.querySelectorAll(`.${reference}-year-option`),
			);
			items.forEach(
				element =>
					(element.className = element.className.replace(' active', '')),
			);

			const item = document.getElementById(event.target.id);
			item.className += ' active';

			const year = event.target.innerHTML;
			var month =
				parseInt(year) === parseInt(actualYear)
					? activeMonth > actualMonth
						? actualMonth
						: activeMonth
					: activeMonth;

			setActiveYear(year);
			if(activeMonth < startingMonth && year == startingYear){
				setActiveMonth(12);
				month = 12;
			}

			if (
				parseInt(year) === parseInt(actualYear) &&
				activeMonth > actualMonth
			) {
				setActiveMonth(actualMonth);
			}

			onClick(year, month);
		}
	};

	const handleMonthClick = event => {
		if (event.target.id) {
			const items = Array.from(
				document.querySelectorAll(`.${reference}-month-option`),
			);
			items.forEach(
				element =>
					(element.className = element.className.replace(' active', '')),
			);

			const item = document.getElementById(event.target.id);
			item.className += ' active';

			const month = event.target.id.replace(`${reference}-month-`, '');

			setActiveMonth(month);
			onClick(activeYear, month);
		}
	};

	return (
		<Container className={variant}>
			<div style={{width: "100%", height: "fit-content"}}>
				<TableContainer>
					<Table>
						<Head className={customHeader ? 'customHeader' : ''}>
							{customHeader || (
								<tr>
									{headers?.map(header => (
										<th key={header}>{header}</th>
									))}
								</tr>
							)}
						</Head>
						<Body>
							{data ? (
								data.length > 0 ? (
									<>
										{hidePagination ?
										
										customData ? customData
											: data?.map((items, i) => (
													<tr key={`${items[0]}${i}`}>
														{items.map((item, j) => (
															<td
																style={{
																	fontWeight:
																	negritoLastFlag === 1 && i === data.length - 1 ? 'bold' : 'normal',
																}}
																key={`${item}${j}`}
															>
																{item}
															</td>
														))}
													</tr>
											))
											:
											customData
												? customData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
												: data?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((items, i) => (
														<tr key={`${items[0]}${i}`}>
															{items.map((item, j) => (
																<td
																	style={{
																		fontWeight:
																		negritoLastFlag === 1 && i === data.length - 1 ? 'bold' : 'normal',
																	}}
																	key={`${item}${j}`}
																>
																	{item}
																</td>
															))}
														</tr>
												))

											}
									</>
								) : (
									<tr className="loading">
										<td
											className="loading"
											colSpan={
												customHeader
													? customHeader?.props?.count
													: headers?.length
											}
										>
											<LoadingContainer>
												<p>Não há dados a serem exibidos</p>
											</LoadingContainer>
										</td>
									</tr>
								)
							) : (
								<tr className="loading">
									<td
										className="loading"
										colSpan={
											customHeader ? customHeader?.props?.count : headers?.length
										}
									>
										<LoadingContainer>
											<Loader type="Oval" height={100} width={100} />{' '}
											{/*Grid, TailSpin, ThreeDots*/}
										</LoadingContainer>
									</td>
								</tr>
							)}
						</Body>
					</Table>
				</TableContainer>

				{data && !hidePagination ? data.length > 0 ? !hidingPagination ? 
					<div style={{display: "grid", justifyContent: "flex-end", paddingTop: 10}}>
						<Pagination
							size="small"
							count={data ?  data.length/rowsPerPage >= 1 ? Math.ceil(data.length/rowsPerPage) : 1: 0}
							onChange={handleChangePage}
							page={page}
							shape="rounded"
							style={{color: textColorPagination, fontSize: "15px", display: "flex", justifyContent: "flex-end"}}
							renderItem= {(item) => <PaginationItem size="small" classes={{selected: classes.selected, root: classes.root}}  {...item} />}
							hideNextButton={true}
							hidePrevButton={true}
							/>
					</div>
				: <></> : <></>  : <></> 

				}

				{data && !hidePagination ? data.length > 0 ? 
					<div style={{display: "grid", justifyContent: "flex-end"}}>
						<Button onClick={hideShowPagination} style={{ color: textColorPagination, display: "flex", alignItems: "center", textTransform: "unset", justifyContent: "flex-end"}}>
							<ClearAllIcon
								onClick={hideShowPagination}
								style={{fontSize: "30px", display: "flex", justifyContent: "flex-end"}}
							/>
							{messagePagination}
						</Button>

					</div> 
				: <></>  : <></> } 

				{showUpdateDate ? 
				
				<HoraAtualizacao>
							<Tooltip title="Último horário atualizado."  placement="bottom-end" arrow>
								<h2 style={{color: textColorPagination}}>
									{updateDate}
								</h2>
							</Tooltip>
							{backgroudColorPagination === "darkblue" ? <img src="images/icone-horario-atualizacao.svg" alt="Não tem"/> : <img src="images/icone-horario-atualizacao2.svg" alt="Não tem"/> }
							
				</HoraAtualizacao>
				
				:  <></> }

			
				
			</div>

			{!hideOptions && (
				<Options>
					<>
						{!hideFilterYear && !hideFilterCalendar && (
							<Tabs onClick={handleYearClick}>
								{anosReferencia ? anosReferencia
								.filter(year => parseInt(year) >= startingYear)
								.map(year => { 	if(parseInt(year) ===  parseInt(actualYear))
													return (
														<Tab
															id={`${reference}-year-${years_control++}`}
															className={`${reference}-year-option active`}
															style={{margin: 5}}
														>
															{year+ ' '}
														</Tab>
													);
											   else
													return (
														<Tab
															id={`${reference}-year-${years_control++}`}
															className={`${reference}-year-option`}
															style={{margin: 5}}
														>
															{year + ' '}
														</Tab>
											);
								}) : ""

								}
							</Tabs>
						)}

						<Filtro>
							{!hideFilterMonth && !hideFilterCalendar && (
								<Items onClick={handleMonthClick}>
									{months
										.filter(month =>
											parseInt(activeYear) === parseInt(actualYear)
												? month.number <= actualMonth
												: month.number <= 12,
										)
										.filter(month=> 
											parseInt(activeYear) === startingYear
											? month.number >= startingMonth
											: month.number <= 12
											)
										.map(month => {
											return (
												<Item
													key={month.name}
													id={`${reference}-month-${month.number}`}
													className={`${reference}-month-option ${
														month.number === activeMonth ? 'active' : ''
													}`}
												>
													{month.name}
												</Item>
											);
										})}
								</Items>
							)}
							{!hideDownloadButton && (
								<Download onClick={onDownloadClick}>
									<DownloadIcon />
									<span>{buttonText}</span>
								</Download>
							)}
						</Filtro>
					</>
				</Options>
			)}
		</Container>
	);
};

export default TabelaCalendario;
