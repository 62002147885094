import styled from 'styled-components';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import AttachMoney from '@material-ui/icons/AttachMoney';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';

export const Container = styled.div`
	display: none;
	justify-content: center;
	align-items: center;

	width: 75px;
	height: 75px;
	position: fixed;

	right: 35px;
	bottom: 35px;

	border-radius: 50%;
	box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);

	background-color: var(--dark-blue);
	color: var(--white);

	font-size: 35px;

	z-index: 2;

	cursor: pointer;

	transition: all ease 0.2s;

	&.show {
		display: flex;
	}

	:hover {
		background-color: var(--light-blue);
	}
	@media (max-width: 768px) {
		width: 60px;
		height: 60px;
		right: 25px;
	}
`;

export const ArrowUp = styled(ArrowDropUp)`
	font-size: 50px;
	@media (max-width: 768px) {
		font-size: 30px;
	}
`;
export const Money = styled(AttachMoney)`
	font-size: 50px;
	@media (max-width: 768px) {
		font-size: 30px;
	}
`;
export const Calendar = styled(CalendarToday)`
	font-size: 50px;
	@media (max-width: 768px) {
		font-size: 30px;
	}
`;

export const OffContainer = styled.div`
	display: none;
	justify-content: center;
	align-items: center;
	font-size: 150px;

	width: 75px;
	height: 75px;
	position: fixed;

	right: 35px;
	bottom: 125px;

	border-radius: 50%;
	border: 4px solid var(--dark-blue);
	box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);

	background-color: var(--light-blue);
	color: var(--white);

	font-size: 35px;

	z-index: 2;

	cursor: pointer;

	transition: all ease 0.2s;

	&.show {
		display: flex;
	}

	:hover {
		background-color: var(--light-blue);
		transform: scale(0.9);
	}
	@media (max-width: 768px) {
		width: 60px;
		height: 60px;
		right: 25px;
	}
`;
export const ButtonModal = styled(Button)`
	display: none;

	@media (max-width: 768px) {
		width: 60px;
		height: 60px;
		right: 25px;
		bottom: 120px;
		justify-content: center;
		align-items: center;
		position: fixed;
		border-radius: 50%;
		box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);

		background-color: var(--dark-blue);
		color: var(--white);

		font-size: 15px;

		z-index: 2;

		cursor: pointer;

		transition: all ease 0.2s;

		&.show {
			display: flex;
		}

		:hover {
			background-color: var(--light-blue);
		}
	}
`;

export const BoxUp = styled(Box)`
	display: none;
	box-shadow: none;
	transform: none;

	@media (max-width: 768px) {
		position: absolute;
		display: flex;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		align-items: center;
		justify-items: center;
		width: 90%;
		height: 70%;
		border: 2px solid var(--dark-blue);
		box-shadow: 40;
		background-color: white;
		padding: 1rem > hr {
			position: absolute;
			width: 63%;
			overflow: hidden;
			border: 3px dashed #fff;
		}

		> ul {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			width: 100%;
			height: 70%;
		}

		> ul li {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-top: 1rem;
		}

		> ul li p {
			color: black;
			font-size: 14px;
			text-align: center;
			font-weight: 100;
			line-height: 1.2;
		}

		> ul li span {
			width: 40px;
			height: 40px;
			background-color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			margin-bottom: 5px;
			border: none;
		}

		> ul li span img {
			width: 50%;
			color: #1250c5;
		}
	}
`;
