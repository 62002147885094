import styled from 'styled-components';

export const Container = styled.div`
	h2 {
		text-align: left;
	}

	.tooltipHeader {
		font-size: 16px;
		font-weight: bold;

		color: var(--table-text);

		margin-bottom: 10px;
		margin-left: 10px;
	}

	.tooltipInfo {
		td {
			font-size: 13px;
			/* font-weight: bold; */

			/* border: 1px solid black; */

			text-align: left;

			padding: 5px 10px 5px 10px;
		}

		div {
			padding: 5px 0;

			display: flex;
			justify-content: center;

			width: 150px;
			color: var(--white);
		}
	}
`;
export const Title = styled.h1`
	background-color: white;
	color: #4d4d4d;
	font-size: 1em;
	font-weight: 100;
	text-transform: uppercase;
	padding: 2px 0 11px 0;
	-webkit-box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	-moz-box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	box-shadow: 1px 4px 13px -9px rgba(0, 0, 0, 0.69);
	display: inline-block;
	width: 100%;
`;
export const PeriodOptions = styled.ul`
	display: flex;
	flex-direction: row;
`;
export const PeriodOption = styled.li`
	color: var(--dark-blue);

	margin: 0 10px;

	cursor: pointer;

	transition: all ease 0.2s;

	&.active {
		font-weight: bold;

		${props => props.blockcursor && 'pointer-events: none; opacity: 1;'}
	}

	:hover {
		color: var(--light-blue);
	}

	${props => props.blockcursor && 'pointer-events: none; opacity: 0.6;'}
`;
export const DateOptions = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	@media (max-width: 780px) {
		flex-direction: column;

		justify-content: center;
	}
`;
export const BoxAcumulado = styled.div`
	display: flex;
	justify-content: center;

	width: 100%;
	min-height: 520px;

	padding-top: 20px;
	padding-bottom: 70px;

	@media (max-width: 780px) {
		padding-top: 30px;
	}
`;

export const BoxAcumuladoMensal = styled.div`
	background-image: url('/asset-management/etf-latamtech/images/ETF-btg-pactual-rentabilidade-mensal.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const BoxAcumuladoMensalVolume = styled.div`
	background-image: url('/asset-management/etf-latamtech/images/ETF-btg-pactual-rentabilidade-mensal_Volume.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const BoxMensal = styled.div`
	display: flex;
	justify-content: center;

	width: 100%;
	min-height: 700px;

	@media (max-width: 780px) {
		justify-content: end;

		width: 100%;
		height: 600px;
		min-height: 600px;
		margin-top: 0;

		background-image: none;
	}
`;
export const BoxVolume = styled.div`
	display: flex;
	justify-content: flex-start;

	width: 100%;
	min-height: 780px;

	@media (max-width: 780px) {
		justify-content: end;

		width: 100%;
		height: 600px;
		min-height: 600px;
		margin-top: 0;

		background-image: none;
	}
`;
export const BoxEvolucao = styled.div`
	display: flex;
	justify-content: center;

	width: 100%;
	min-height: 500px;
`;
export const ContentAcumulado = styled.div`
	width: 90%;
	max-width: 1141px;

	> h2 {
		color: var(--dark-blue);
	}

	.LoadingContainer svg {
		stroke: var(--dark-blue);
	}

	@media (max-width: 780px) {
		width: 100%;

		> h2 {
			margin-bottom: 20px;
			padding-left: 15px;
		}
	}
`;
export const ContentMensal = styled.div`
	width: 90%;
	max-width: 1141px;

	display: flex;
	justify-content: flex-end;

	> div {
		width: 1110px;
		padding: 80px;
		margin: -30px 0 0 0;

		background-color: var(--dark-blue);

		> h2 {
			color: #fff;
		}
	}

	@media (max-width: 780px) {
		width: 100%;

		> div {
			width: 100%;

			padding: 30px 15px;
			margin: 0;

			overflow-x: scroll;
			overflow-y: hidden;
		}
	}
`;
export const ContentVolume = styled.div`
	width: 100%;
	max-width: 1141px;
	margin-left: 5%;

	> div {
		padding: 80px 80px 20px;
		margin: 80px 0;
		max-height: 780px;

		background-color: var(--white);

		> h2 {
			color: var(--dark-blue);
		}
	}

	@media (max-width: 780px) {
		width: 100%;

		> div {
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 30px 15px;
			background-color: var(--light-blue);

			overflow-x: scroll;
			overflow-y: hidden;

			> h2 {
				color: var(--white);
			}
		}
	}
`;
export const ContentEvolucao = styled.div`
	width: 90%;
	max-width: 1141px;
	min-height: 780px;

	border: white solid 1px;
	padding: 80px 0;

	> h2 {
		color: var(--dark-blue);
	}

	@media (max-width: 780px) {
		width: 100%;

		padding: 30px 15px;

		overflow-x: scroll;
		overflow-y: hidden;
	}
`;
export const TabelaMensal = styled.div`
	min-width: 600px;
	width: 100%;
	height: 100%;
	max-height: 710px;

	margin-top: 20px;
	/* border: 1px solid var(--black); */

	@media (max-width: 780px) {
		height: 95%;
	}
`;
export const TabelaVolume = styled.div`
	min-width: 600px;
	width: 100%;
	height: 100%;
	max-height: 710px;

	margin-top: 20px;
	/* border: 1px solid var(--black); */

	@media (max-width: 780px) {
		height: 95%;
	}
`;
export const TabelaEvolucao = styled.div`
	min-width: 600px;
	width: 100%;
	max-height: 520px;

	margin-top: 20px;
	/* border: 1px solid var(--black); */

	@media (max-width: 780px) {
		height: 95%;
	}
`;

export const HoraAtualizacaoMensal = styled.div`
	width: 79%;
	display: flex;
	justify-content: flex-end;
	height: auto;
	margin-top: -50px;

	> h2 {
		min-width: 100px;
		color: white;
		font-size: 14px;
		margin-top: unset;
		align: center;
	}

	img {
		width: unset;
		height: 18px;
		z-index: 0;
		margin-left: 5px;
		margin-top: unset;
	}
`;

export const HoraAtualizacaoVolume = styled.div`
	width: 83%;
	display: flex;
	justify-content: flex-end;
	height: auto;
	margin-top: 0px;
	margin-bottom: 25px;

	> h2 {
		min-width: 100px;
		color: darkblue;
		font-size: 14px;
		align: center;
	}

	img {
		width: unset;
		height: 18px;
		z-index: 0;
		margin-left: 5px;
	}
`;

export const HoraAtualizacaoDiaria = styled.div`
	width: 85%;
	display: flex;
	justify-content: flex-end;
	height: auto;
	margin-top: 0px;

	> h2 {
		min-width: 100px;
		color: darkblue;
		font-size: 14px;
		align: center;
	}

	img {
		width: unset;
		height: 18px;
		z-index: 0;
		margin-left: 5px;
	}
`;

export const CTAContainer = styled.div`
	width: 40%;
	margin: auto;
	padding-bottom: 20px;
	background-color: transparent !important;
	@media (max-width: 768px) {
		width: 90%;
	}
`;
