import React, { useContext } from 'react';
import { Hidden } from '@material-ui/core';

import { MainContext } from '../../store/context/main';
import { Container, Items } from './styles';

function Submenu(props) {
	const { state } = useContext(MainContext);

	return (
		<Hidden xsDown>
			<Container id="submenu" className={state.fixedMenu ? 'fixed' : ''}>
				<Items>{props.children}</Items>
			</Container>
		</Hidden>
	);
}

export default Submenu;
