import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { MainContext } from '../../store/context/main';
import { Container, Menu } from './styles';
import Aba from '../aba';
import { Hidden } from '@material-ui/core';

const menuLabel = {
	menu1: 'Sobre FGTS e FMP',
	menu2: 'Visão geral',
	menu3: 'Características',
	menu7: 'Documentos',
	menu4: 'Como Investir',
};

const itemLabel = {
	'sobre-fgts-eletrobras-e-fmp': 'menu1',
	'visao-geral': 'menu2',
	caracteristicas: 'menu3',
	documentos: 'menu7',
	'como-investir': 'menu4',
};

function Main() {
	const location = useLocation();
	const { state, dispatch } = useContext(MainContext);

	const handleClick = selected => {
		dispatch({
			type: 'SET_SELECTED_MENU',
			selectedMenu: selected,
		});
	};

	const checkActive = menu => {
		// console.log(menuLabel[state.selectedMenu]);

		if (menuLabel[state.selectedMenu]) {
			document.title =
				menuLabel[state.selectedMenu] +
				' - Portal FMP FGTS Eletrobras | BTG Pactual';
		}

		return state.selectedMenu === menu ? 1 : 0;
	};

	const handleScroll = e => {
		if (window.pageYOffset > 400) {
			dispatch({ type: 'SET_FIXED_MENU', fixedMenu: true });
		} else {
			dispatch({ type: 'SET_FIXED_MENU', fixedMenu: false });
		}
	};

	useEffect(() => {
		let path = location.pathname
			.replace('/asset-management/fgts-eletrobras', '')
			.replace('/', '');

		if (path === '') {
			path = 'como-investir';
		}

		handleClick(itemLabel[path]);

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
		// eslint-disable-next-line
	}, []);

	return (
		<Hidden smDown>
			<Container id="menu-container">
				<Menu id="menu" className={state.fixedMenu ? 'fixed' : ''}>
					<Aba
						to="/asset-management/fgts-eletrobras/como-investir"
						label="COMO INVESTIR"
						onClick={() => handleClick('menu4')}
						active={checkActive('menu4')}
					/>
					<Aba
						to="/asset-management/fgts-eletrobras/sobre-fgts-eletrobras-e-fmp"
						label="SOBRE FGTS E FMP"
						onClick={() => handleClick('menu1')}
						active={checkActive('menu1')}
					/>
					<Aba
						to="/asset-management/fgts-eletrobras/visao-geral"
						label="VISÃO GERAL"
						onClick={() => handleClick('menu2')}
						active={checkActive('menu2')}
					/>
					<Aba
						to="/asset-management/fgts-eletrobras/caracteristicas"
						label="CARACTERÍSTICAS"
						onClick={() => handleClick('menu3')}
						active={checkActive('menu3')}
					/>
					<Aba
						to="/asset-management/fgts-eletrobras/documentos"
						label="DOCUMENTOS"
						onClick={() => handleClick('menu7')}
						active={checkActive('menu7')}
					/>
				</Menu>
			</Container>
		</Hidden>
	);
}

export default Main;
