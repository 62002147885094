import React, { useState, useEffect, useRef, useContext } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { MainContext } from '../../store/context/main';

import { CTAButton, Submenu, SubmenuItem } from '../../components';
import { documentsService /*, etfService*/ } from '../../service/';

import { getDateTimeString, smoothScroll } from '../../utils/functions';

import VisalGeralTexts from './texts';

import {
	Title,
	Container,
	Objetivo,
	ObjetivoConteudo,
	Politica,
	PoliticaConteudo,
	Publico,
	PublicoConteudo,
	PublicoText,
	PublicoImagem,
	BlockText,
	Regras,
	RegrasConteudo,
	RegrasImg,
	RegrasTexto,
	CTAContainer,
} from './styles';

export default () => {
	const [result, setResult] = useState('');

	const objetivoRef = useRef(null);
	const politicaRef = useRef(null);
	const publicoRef = useRef(null);
	const regrasRef = useRef(null);
	const { state, dispatch } = useContext(MainContext);

	const getResult = async () => {
		try {
			throw new Error(); //force mock

			// const response = await etfService.Geral();
			// const responseData = await response.data;

			// setResult(responseData.ObjJsonResultado);
		} catch (err) {
			setResult(VisalGeralTexts);

			// console.error('Ocorreu um erro ao chamar a API Geral - Erro: ', err);
		}
	};

	const getFile = async path => {
		try {
			const response = await documentsService.downloadDocumento(path);
			const responseData = await response.data;

			const blob = new Blob([responseData], {
				type: 'application/pdf',
			});

			const downloadUrl = window.URL.createObjectURL(blob);

			const link = document.createElement('a');

			link.href = downloadUrl;
			link.download = `${path.replace('/', '')}PDF_${getDateTimeString()}.pdf`;

			document.body.appendChild(link);

			link.click();
			link.remove();
		} catch (err) {
			console.error(
				'Ocorreu um erro ao chamar a API de Download - Erro: ',
				err,
			);
		}
	};

	useEffect(() => {
		const getData = async () => {
			await getResult();
		};

		getData();

		dispatch({
			type: 'SET_CURRENT_ROUTE',
			currentRoute: 'Visão Geral',
		});
	}, []);

	const handleDownloadClick = path => getFile(path);

	return (
		<Container>
			<Hidden mdUp>
				<Title>Visão geral</Title>
			</Hidden>
			<Hidden smDown>
				<Submenu>
					<SubmenuItem
						onClick={() => smoothScroll('#objetivo')}
						label="Objetivo"
					/>
					<SubmenuItem
						onClick={() => smoothScroll('#politica')}
						label="Política de investimento"
					/>
					<SubmenuItem
						onClick={() => smoothScroll('#publico')}
						label="Público alvo"
					/>
				</Submenu>
			</Hidden>

			<Objetivo>
				<ObjetivoConteudo ref={objetivoRef}>
					<div>
						<h2 id="objetivo">Objetivo</h2>
						<p
							style={{ textAlign: 'left', fontSize: '14px', lineHeight: '1.5' }}
						>
							O <b>BTG Pactual Reference FMP FGTS Eletrobras</b> tem como
							objetivo adquirir ações ordinárias da Eletrobras, por meio de
							oferta pública de distribuição primária e secundária de ações
							ordinárias no Brasil, a ser promovida pela Eletrobras e pela União
							Federal.
						</p>
					</div>
				</ObjetivoConteudo>
			</Objetivo>
			<Politica>
				<PoliticaConteudo ref={politicaRef}>
					<h2 id="politica">Política de investimento</h2>
					<p style={{ textAlign: 'justify' }}>
						{result.Perfil?.Politicainvestimento}
					</p>
					<br />
					<p>
						<strong>
							Não deixe de ler os Avisos legais na parte inferior deste site.
						</strong>
					</p>
				</PoliticaConteudo>
			</Politica>
			<Publico ref={publicoRef}>
				<PublicoConteudo>
					<PublicoText>
						<h2 id="publico" style={{ width: '100%' }}>
							Público alvo
						</h2>
						<p style={{ textAlign: 'left' }}>{result.Perfil?.PublicoAlvo}</p>
						{/* <BlockText>
							Para detalhamento dos fatores de risco,{' '}
							<a
								style={{
									display: 'inline',
									textDecoration: 'underline',
									color: '#FFF',
								}}
								target="_blank"
								href="https://www.fgts.gov.br/Pages/default.aspx"
							>
								clique aqui
							</a>{' '}
							ou acesse o menu Documentos &gt; Fatores de risco.
						</BlockText> */}
						<CTAContainer>
							<CTAButton />
						</CTAContainer>
					</PublicoText>
				</PublicoConteudo>
				<PublicoImagem />
			</Publico>
		</Container>
	);
};
